import i18next from "i18next";

i18next.addResources(
  'en-GB',
  'science',
  {
    'Industrialization.name': 'Industrialisation',
    'LaborUnion.name': 'Labour Union',
  }
);
