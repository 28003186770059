import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-city-improvement/en-GB.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-city-improvement/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-city/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-civilization/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-diplomacy/en-GB.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-diplomacy/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-government/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-science/en-GB.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-science/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-spaceship/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-trade-rate/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-unit/en-GB.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-unit/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-wonder/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/civ1-world/en.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/local/en-GB.ts';
import '/Users/dominik.hastings/Code/civ-clone/web-renderer/translations/local/en.ts';
