import i18next from "i18next";

i18next.addResources(
  'en',
  'science',
  {
    'AdvancedFlight.name': 'Advanced Flight',
    'Alphabet.name': 'Alphabet',
    'Astronomy.name': 'Astronomy',
    'AtomicTheory.name': 'Atomic Theory',
    'Automobile.name': 'Automobile',
    'Banking.name': 'Banking',
    'BridgeBuilding.name': 'Bridge Building',
    'BronzeWorking.name': 'Bronze Working',
    'CeremonialBurial.name': 'Ceremonial Burial',
    'Chemistry.name': 'Chemistry',
    'Chivalry.name': 'Chivalry',
    'CodeOfLaws.name': 'Code of Laws',
    'Combustion.name': 'Combustion',
    'Communism.name': 'Communism',
    'Computers.name': 'Computers',
    'Conscription.name': 'Conscription',
    'Construction.name': 'Construction',
    'Corporation.name': 'Corporation',
    'Currency.name': 'Currency',
    'Democracy.name': 'Democracy',
    'Electricity.name': 'Electricity',
    'Electronics.name': 'Electronics',
    'Engineering.name': 'Engineering',
    'Explosives.name': 'Explosives',
    'Feudalism.name': 'Feudalism',
    'Flight.name': 'Flight',
    'FusionPower.name': 'Fusion Power',
    'GeneticEngineering.name': 'Genetic Engineering',
    'Gunpowder.name': 'Gunpowder',
    'HorsebackRiding.name': 'Horseback Riding',
    'Industrialization.name': 'Industrialization',
    'Invention.name': 'Invention',
    'IronWorking.name': 'Iron Working',
    'LaborUnion.name': 'Labor Union',
    'Literacy.name': 'Literacy',
    'Magnetism.name': 'Magnetism',
    'MapMaking.name': 'Map Making',
    'Masonry.name': 'Masonry',
    'MassProduction.name': 'Mass Production',
    'Mathematics.name': 'Mathematics',
    'Medicine.name': 'Medicine',
    'Metallurgy.name': 'Metallurgy',
    'Monarchy.name': 'Monarchy',
    'Mysticism.name': 'Mysticism',
    'Navigation.name': 'Navigation',
    'NuclearFission.name': 'Nuclear Fission',
    'NuclearPower.name': 'Nuclear Power',
    'Philosophy.name': 'Philosophy',
    'Physics.name': 'Physics',
    'Plastics.name': 'Plastics',
    'Pottery.name': 'Pottery',
    'Railroad.name': 'Railroad',
    'Recycling.name': 'Recycling',
    'Refining.name': 'Refining',
    'Religion.name': 'Religion',
    'Robotics.name': 'Robotics',
    'Rocketry.name': 'Rocketry',
    'SpaceFlight.name': 'Space Flight',
    'SteamEngine.name': 'Steam Engine',
    'Steel.name': 'Steel',
    'Superconductor.name': 'Superconductor',
    'TheRepublic.name': 'The Republic',
    'TheWheel.name': 'The Wheel',
    'TheoryOfGravity.name': 'Theory of Gravity',
    'Trade.name': 'Trade',
    'University.name': 'University',
    'Writing.name': 'Writing',
  }
);
