import i18next from "i18next";

i18next.addResources(
  'en',
  'unit',
  {
    // Units
    'Artillery.name': 'Artillery',
    'Battleship.name': 'Battleship',
    'Bomber.name': 'Bomber',
    'Cannon.name': 'Cannon',
    'Caravan.name': 'Caravan',
    'Carrier.name': 'Carrier',
    'Catapult.name': 'Catapult',
    'Chariot.name': 'Chariot',
    'Cruiser.name': 'Cruiser',
    'Diplomat.name': 'Diplomat',
    'Fighter.name': 'Fighter',
    'Frigate.name': 'Frigate',
    'Horseman.name': 'Horseman',
    'Ironclad.name': 'Ironclad',
    'Knight.name': 'Knight',
    'MechanizedInfantry.name': 'Mechanized Infantry',
    'Musketman.name': 'Musketman',
    'Nuclear.name': 'Nuclear',
    'Rifleman.name': 'Rifleman',
    'Sail.name': 'Sail',
    'Settlers.name': 'Settlers',
    'Spearman.name': 'Spearman',
    'Submarine.name': 'Submarine',
    'Swordman.name': 'Swordman',
    'Tank.name': 'Tank',
    'Transport.name': 'Transport',
    'Trireme.name': 'Trireme',
    'Warrior.name': 'Warrior',

    // Improvements
    'Improvement.Fortified.name': 'Fortified',
    'Improvement.Veteran.name': 'Veteran',

    // Yields
    'Yield.Attack.name': 'Attack',
    'Yield.Defence.name': 'Defence',
    'Yield.Movement.name': 'Movement',
    'Yield.Moves.name': 'Moves',
    'Yield.Visibility.name': 'Visibility',

    // Actions
    'Action.Attack.name': 'Attack',
    'Action.BuildIrrigation.name': 'Build irrigation',
    'Action.BuildMine.name': 'Build mine',
    'Action.BuildRailroad.name': 'Build railroad',
    'Action.BuildRoad.name': 'Build road',
    'Action.CaptureCity.name': 'Capture city',
    'Action.ClearForest.name': 'Clear forest',
    'Action.ClearJungle.name': 'Clear jungle',
    'Action.ClearSwamp.name': 'Clear swamp',
    'Action.Disband.name': 'Disband',
    'Action.Disembark.name': 'Disembark',
    'Action.Embark.name': 'Embark',
    'Action.Fortify.name': 'Fortify',
    'Action.FoundCity.name': 'Found a new city',
    'Action.Move.name': 'Move',
    'Action.NoOrders.name': 'No orders',
    'Action.Pillage.name': 'Pillage',
    'Action.PlantForest.name': 'Plant forest',
    'Action.Sleep.name': 'Sleep',
    'Action.SneakAttack.name': 'Sneak attack',
    'Action.SneakCaptureCity.name': 'Sneak attack',
    'Action.Unload.name': 'Unload',

    'Busy.BuildingIrrigation.name': 'Building irrigation',
    'Busy.BuildingMine.name': 'Building mine',
    'Busy.BuildingRoad.name': 'Building road',
    'Busy.BuildingRailroad.name': 'Building railroad',
    'Busy.ClearingForest.name': 'Clearing forest',
    'Busy.ClearingJungle.name': 'Clearing jungle',
    'Busy.ClearingSwamp.name': 'Clearing swamp',
    'Busy.Fortifying.name': 'Fortifying',
    'Busy.Sleeping.name': 'Sleeping',
    'Busy.PlantingForest.name': 'Planting forest',

    'Busy.BuildingIrrigation.icon': 'I',
    'Busy.BuildingMine.icon': 'M',
    'Busy.BuildingRoad.icon': 'R',
    'Busy.BuildingRailroad.icon': 'RR',
    'Busy.ClearingForest.icon': 'CF',
    'Busy.ClearingJungle.icon': 'CJ',
    'Busy.ClearingSwamp.icon': 'CS',
    'Busy.Fortifying.icon': 'F',
    'Busy.Sleeping.icon': 'S',
    'Busy.PlantingForest.icon': 'PF',
  }
);
