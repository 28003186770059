import i18next from "i18next";

i18next.addResources(
  'en',
  'city',
  {
    'None': 'None',

    // American
    'American.Washington.name': 'Washington',
    'American.New York.name': 'New York',
    'American.Los Angeles.name': 'Los Angeles',
    'American.Chicago.name': 'Chicago',
    'American.Houston.name': 'Houston',
    'American.Phoenix.name': 'Phoenix',
    'American.Philadelphia.name': 'Philadelphia',
    'American.San Antonio.name': 'San Antonio',
    'American.San Diego.name': 'San Diego',
    'American.Dallas.name': 'Dallas',
    'American.San Jose.name': 'San Jose',
    'American.Austin.name': 'Austin',
    'American.Jacksonville.name': 'Jacksonville',
    'American.Fort Worth.name': 'Fort Worth',
    'American.Columbus.name': 'Columbus',
    'American.San Francisco.name': 'San Francisco',
    'American.Charlotte.name': 'Charlotte',
    'American.Indianapolis.name': 'Indianapolis',
    'American.Seattle.name': 'Seattle',
    'American.Denver.name': 'Denver',
    'American.Boston.name': 'Boston',
    'American.El Paso.name': 'El Paso',
    'American.Detroit.name': 'Detroit',
    'American.Nashville.name': 'Nashville',
    'American.Portland.name': 'Portland',
    'American.Memphis.name': 'Memphis',
    'American.Oklahoma City.name': 'Oklahoma City',
    'American.Las Vegas.name': 'Las Vegas',
    'American.Louisville.name': 'Louisville',
    'American.Baltimore.name': 'Baltimore',
    'American.Milwaukee.name': 'Milwaukee',
    'American.Albuquerque.name': 'Albuquerque',
    'American.Tucson.name': 'Tucson',
    'American.Fresno.name': 'Fresno',
    'American.Mesa.name': 'Mesa',
    'American.Sacramento.name': 'Sacramento',
    'American.Atlanta.name': 'Atlanta',
    'American.Kansas City.name': 'Kansas City',
    'American.Colorado Springs.name': 'Colorado Springs',
    'American.Miami.name': 'Miami',
    'American.Raleigh.name': 'Raleigh',
    'American.Omaha.name': 'Omaha',
    'American.Long Beach.name': 'Long Beach',
    'American.Virginia Beach.name': 'Virginia Beach',
    'American.Oakland.name': 'Oakland',
    'American.Minneapolis.name': 'Minneapolis',
    'American.Tulsa.name': 'Tulsa',
    'American.Arlington.name': 'Arlington',
    'American.Tampa.name': 'Tampa',
    'American.New Orleans.name': 'New Orleans',
    'American.Wichita.name': 'Wichita',
    'American.Cleveland.name': 'Cleveland',
    'American.Bakersfield.name': 'Bakersfield',
    'American.Aurora.name': 'Aurora',
    'American.Anaheim.name': 'Anaheim',
    'American.Honolulu.name': 'Honolulu',
    'American.Santa Ana.name': 'Santa Ana',
    'American.Riverside.name': 'Riverside',
    'American.Corpus Christi.name': 'Corpus Christi',
    'American.Lexington.name': 'Lexington',
    'American.Stockton.name': 'Stockton',
    'American.Henderson.name': 'Henderson',
    'American.Saint Paul.name': 'Saint Paul',
    'American.St. Louis.name': 'St. Louis',
    'American.Cincinnati.name': 'Cincinnati',
    'American.Pittsburgh.name': 'Pittsburgh',
    'American.Greensboro.name': 'Greensboro',
    'American.Anchorage.name': 'Anchorage',
    'American.Plano.name': 'Plano',
    'American.Lincoln.name': 'Lincoln',
    'American.Orlando.name': 'Orlando',
    'American.Irvine.name': 'Irvine',
    'American.Newark.name': 'Newark',
    'American.Toledo.name': 'Toledo',
    'American.Durham.name': 'Durham',
    'American.Chula Vista.name': 'Chula Vista',
    'American.Fort Wayne.name': 'Fort Wayne',
    'American.Jersey City.name': 'Jersey City',
    'American.St. Petersburg.name': 'St. Petersburg',
    'American.Laredo.name': 'Laredo',
    'American.Madison.name': 'Madison',
    'American.Chandler.name': 'Chandler',
    'American.Buffalo.name': 'Buffalo',
    'American.Lubbock.name': 'Lubbock',
    'American.Scottsdale.name': 'Scottsdale',
    'American.Reno.name': 'Reno',
    'American.Glendale.name': 'Glendale',
    'American.Gilbert.name': 'Gilbert',
    'American.Winston-Salem.name': 'Winston-Salem',
    'American.North Las Vegas.name': 'North Las Vegas',
    'American.Norfolk.name': 'Norfolk',
    'American.Chesapeake.name': 'Chesapeake',
    'American.Garland.name': 'Garland',
    'American.Irving.name': 'Irving',
    'American.Hialeah.name': 'Hialeah',
    'American.Fremont.name': 'Fremont',
    'American.Boise.name': 'Boise',
    'American.Richmond.name': 'Richmond',
    'American.Baton Rouge.name': 'Baton Rouge',
    'American.Spokane.name': 'Spokane',
    'American.Des Moines.name': 'Des Moines',
    'American.Tacoma.name': 'Tacoma',
    'American.San Bernardino.name': 'San Bernardino',
    'American.Modesto.name': 'Modesto',
    'American.Fontana.name': 'Fontana',
    'American.Santa Clarita.name': 'Santa Clarita',
    'American.Birmingham.name': 'Birmingham',
    'American.Oxnard.name': 'Oxnard',
    'American.Fayetteville.name': 'Fayetteville',
    'American.Moreno Valley.name': 'Moreno Valley',
    'American.Rochester.name': 'Rochester',
    'American.Huntington Beach.name': 'Huntington Beach',
    'American.Salt Lake City.name': 'Salt Lake City',
    'American.Grand Rapids.name': 'Grand Rapids',
    'American.Amarillo.name': 'Amarillo',
    'American.Yonkers.name': 'Yonkers',
    'American.Montgomery.name': 'Montgomery',
    'American.Akron.name': 'Akron',
    'American.Little Rock.name': 'Little Rock',
    'American.Huntsville.name': 'Huntsville',
    'American.Augusta.name': 'Augusta',
    'American.Port St. Lucie.name': 'Port St. Lucie',
    'American.Grand Prairie.name': 'Grand Prairie',
    'American.Tallahassee.name': 'Tallahassee',
    'American.Overland Park.name': 'Overland Park',
    'American.Tempe.name': 'Tempe',
    'American.McKinney.name': 'McKinney',
    'American.Mobile.name': 'Mobile',
    'American.Cape Coral.name': 'Cape Coral',
    'American.Shreveport.name': 'Shreveport',
    'American.Frisco.name': 'Frisco',
    'American.Knoxville.name': 'Knoxville',
    'American.Worcester.name': 'Worcester',
    'American.Brownsville.name': 'Brownsville',
    'American.Vancouver.name': 'Vancouver',
    'American.Fort Lauderdale.name': 'Fort Lauderdale',
    'American.Sioux Falls.name': 'Sioux Falls',
    'American.Ontario.name': 'Ontario',
    'American.Chattanooga.name': 'Chattanooga',
    'American.Providence.name': 'Providence',
    'American.Newport News.name': 'Newport News',
    'American.Rancho Cucamonga.name': 'Rancho Cucamonga',
    'American.Santa Rosa.name': 'Santa Rosa',
    'American.Oceanside.name': 'Oceanside',
    'American.Salem.name': 'Salem',
    'American.Elk Grove.name': 'Elk Grove',
    'American.Garden Grove.name': 'Garden Grove',
    'American.Pembroke Pines.name': 'Pembroke Pines',
    'American.Peoria.name': 'Peoria',
    'American.Eugene.name': 'Eugene',
    'American.Corona.name': 'Corona',
    'American.Cary.name': 'Cary',
    'American.Springfield.name': 'Springfield',
    'American.Fort Collins.name': 'Fort Collins',
    'American.Jackson.name': 'Jackson',
    'American.Alexandria.name': 'Alexandria',
    'American.Hayward.name': 'Hayward',
    'American.Lancaster.name': 'Lancaster',
    'American.Lakewood.name': 'Lakewood',
    'American.Clarksville.name': 'Clarksville',
    'American.Palmdale.name': 'Palmdale',
    'American.Salinas.name': 'Salinas',
    'American.Hollywood.name': 'Hollywood',
    'American.Pasadena.name': 'Pasadena',
    'American.Sunnyvale.name': 'Sunnyvale',
    'American.Macon.name': 'Macon',
    'American.Pomona.name': 'Pomona',
    'American.Escondido.name': 'Escondido',
    'American.Killeen.name': 'Killeen',
    'American.Naperville.name': 'Naperville',
    'American.Joliet.name': 'Joliet',
    'American.Bellevue.name': 'Bellevue',
    'American.Rockford.name': 'Rockford',
    'American.Savannah.name': 'Savannah',
    'American.Paterson.name': 'Paterson',
    'American.Torrance.name': 'Torrance',
    'American.Bridgeport.name': 'Bridgeport',
    'American.McAllen.name': 'McAllen',
    'American.Mesquite.name': 'Mesquite',
    'American.Syracuse.name': 'Syracuse',
    'American.Midland.name': 'Midland',
    'American.Murfreesboro.name': 'Murfreesboro',
    'American.Miramar.name': 'Miramar',
    'American.Dayton.name': 'Dayton',
    'American.Fullerton.name': 'Fullerton',
    'American.Olathe.name': 'Olathe',
    'American.Orange.name': 'Orange',
    'American.Thornton.name': 'Thornton',
    'American.Roseville.name': 'Roseville',
    'American.Denton.name': 'Denton',
    'American.Waco.name': 'Waco',
    'American.Surprise.name': 'Surprise',
    'American.Carrollton.name': 'Carrollton',
    'American.West Valley City.name': 'West Valley City',
    'American.Charleston.name': 'Charleston',
    'American.Warren.name': 'Warren',
    'American.Hampton.name': 'Hampton',
    'American.Gainesville.name': 'Gainesville',
    'American.Visalia.name': 'Visalia',
    'American.Coral Springs.name': 'Coral Springs',
    'American.Columbia.name': 'Columbia',
    'American.Cedar Rapids.name': 'Cedar Rapids',
    'American.Sterling Heights.name': 'Sterling Heights',
    'American.New Haven.name': 'New Haven',
    'American.Stamford.name': 'Stamford',
    'American.Concord.name': 'Concord',
    'American.Kent.name': 'Kent',
    'American.Santa Clara.name': 'Santa Clara',
    'American.Elizabeth.name': 'Elizabeth',
    'American.Round Rock.name': 'Round Rock',
    'American.Thousand Oaks.name': 'Thousand Oaks',
    'American.Lafayette.name': 'Lafayette',
    'American.Athens.name': 'Athens',
    'American.Topeka.name': 'Topeka',
    'American.Simi Valley.name': 'Simi Valley',
    'American.Fargo.name': 'Fargo',
    'American.Norman.name': 'Norman',
    'American.Abilene.name': 'Abilene',
    'American.Wilmington.name': 'Wilmington',
    'American.Hartford.name': 'Hartford',
    'American.Victorville.name': 'Victorville',
    'American.Pearland.name': 'Pearland',
    'American.Vallejo.name': 'Vallejo',
    'American.Ann Arbor.name': 'Ann Arbor',
    'American.Berkeley.name': 'Berkeley',
    'American.Allentown.name': 'Allentown',
    'American.Richardson.name': 'Richardson',
    'American.Odessa.name': 'Odessa',
    'American.Arvada.name': 'Arvada',
    'American.Cambridge.name': 'Cambridge',
    'American.Sugar Land.name': 'Sugar Land',
    'American.Beaumont.name': 'Beaumont',
    'American.Lansing.name': 'Lansing',
    'American.Evansville.name': 'Evansville',
    'American.Independence.name': 'Independence',
    'American.Fairfield.name': 'Fairfield',
    'American.Provo.name': 'Provo',
    'American.Clearwater.name': 'Clearwater',
    'American.College Station.name': 'College Station',
    'American.West Jordan.name': 'West Jordan',
    'American.Carlsbad.name': 'Carlsbad',
    'American.El Monte.name': 'El Monte',
    'American.Murrieta.name': 'Murrieta',
    'American.Temecula.name': 'Temecula',
    'American.Palm Bay.name': 'Palm Bay',
    'American.Costa Mesa.name': 'Costa Mesa',
    'American.Westminster.name': 'Westminster',
    'American.North Charleston.name': 'North Charleston',
    'American.Miami Gardens.name': 'Miami Gardens',
    'American.Manchester.name': 'Manchester',
    'American.High Point.name': 'High Point',
    'American.Downey.name': 'Downey',
    'American.Clovis.name': 'Clovis',
    'American.Pompano Beach.name': 'Pompano Beach',
    'American.Pueblo.name': 'Pueblo',
    'American.Elgin.name': 'Elgin',
    'American.Lowell.name': 'Lowell',
    'American.Antioch.name': 'Antioch',
    'American.West Palm Beach.name': 'West Palm Beach',
    'American.Everett.name': 'Everett',
    'American.Ventura.name': 'Ventura',
    'American.Centennial.name': 'Centennial',
    'American.Lakeland.name': 'Lakeland',
    'American.Gresham.name': 'Gresham',
    'American.Billings.name': 'Billings',
    'American.Inglewood.name': 'Inglewood',
    'American.Broken Arrow.name': 'Broken Arrow',
    'American.Sandy Springs.name': 'Sandy Springs',
    'American.Jurupa Valley.name': 'Jurupa Valley',
    'American.Hillsboro.name': 'Hillsboro',
    'American.Waterbury.name': 'Waterbury',
    'American.Santa Maria.name': 'Santa Maria',
    'American.Boulder.name': 'Boulder',
    'American.Greeley.name': 'Greeley',
    'American.Daly City.name': 'Daly City',
    'American.Meridian.name': 'Meridian',
    'American.Lewisville.name': 'Lewisville',
    'American.Davie.name': 'Davie',
    'American.West Covina.name': 'West Covina',
    'American.League City.name': 'League City',
    'American.Tyler.name': 'Tyler',
    'American.Norwalk.name': 'Norwalk',
    'American.San Mateo.name': 'San Mateo',
    'American.Green Bay.name': 'Green Bay',
    'American.Wichita Falls.name': 'Wichita Falls',
    'American.Sparks.name': 'Sparks',
    'American.Burbank.name': 'Burbank',
    'American.Rialto.name': 'Rialto',
    'American.Allen.name': 'Allen',
    'American.El Cajon.name': 'El Cajon',
    'American.Las Cruces.name': 'Las Cruces',
    'American.Renton.name': 'Renton',
    'American.Davenport.name': 'Davenport',
    'American.South Bend.name': 'South Bend',
    'American.Vista.name': 'Vista',
    'American.Tuscaloosa.name': 'Tuscaloosa',
    'American.Clinton.name': 'Clinton',
    'American.Edison.name': 'Edison',
    'American.Woodbridge.name': 'Woodbridge',
    'American.San Angelo.name': 'San Angelo',
    'American.Kenosha.name': 'Kenosha',
    'American.Vacaville.name': 'Vacaville',

      // Aztec
      'Aztec.Tenochtitlan.name': 'Tenochtitlan',
      'Aztec.Teotihuacan.name': 'Teotihuacan',
      'Aztec.Tlatelolco.name': 'Tlatelolco',
      'Aztec.Texcoco.name': 'Texcoco',
      'Aztec.Tlaxcala.name': 'Tlaxcala',
      'Aztec.Calixtlahuaca.name': 'Calixtlahuaca',
      'Aztec.Xochicalco.name': 'Xochicalco',
      'Aztec.Tlacopan.name': 'Tlacopan',
      'Aztec.Atzcapotzalco.name': 'Atzcapotzalco',
      'Aztec.Tzintzuntzan.name': 'Tzintzuntzan',
      'Aztec.Malinalco.name': 'Malinalco',
      'Aztec.Tula.name': 'Tula',
      'Aztec.Tamuin.name': 'Tamuin',
      'Aztec.Teayo.name': 'Teayo',
      'Aztec.Cempoala.name': 'Cempoala',
      'Aztec.Chalco.name': 'Chalco',
      'Aztec.Tlalmanalco.name': 'Tlalmanalco',
      'Aztec.Ixtapaluca.name': 'Ixtapaluca',
      'Aztec.Huexotla.name': 'Huexotla',
      'Aztec.Tepexpan.name': 'Tepexpan',
      'Aztec.Tepetlaoxtoc.name': 'Tepetlaoxtoc',
      'Aztec.Chiconautla.name': 'Chiconautla',
      'Aztec.Zitlaltepec.name': 'Zitlaltepec',
      'Aztec.Coyotepec.name': 'Coyotepec',
      'Aztec.Tequixquiac.name': 'Tequixquiac',
      'Aztec.Jilotzingo.name': 'Jilotzingo',
      'Aztec.Tlapanaloya.name': 'Tlapanaloya',
      'Aztec.Tultitan.name': 'Tultitan',
      'Aztec.Ecatepec.name': 'Ecatepec',
      'Aztec.Coatepec.name': 'Coatepec',
      'Aztec.Chalchiuites.name': 'Chalchiuites',
      'Aztec.Chiauhita.name': 'Chiauhita',
      'Aztec.Chapultepec.name': 'Chapultepec',
      'Aztec.Itzapalapa.name': 'Itzapalapa',
      'Aztec.Ayotzinco.name': 'Ayotzinco',
      'Aztec.Iztapam.name': 'Iztapam',

      // Babylonian
      'Babylonian.Babilim.name': 'Babilim',
      'Babylonian.Eshnunna.name': 'Eshnunna',
      'Babylonian.Diniktum.name': 'Diniktum',
      'Babylonian.Tutub.name': 'Tutub',
      'Babylonian.Der.name': 'Der',
      'Babylonian.Sippar.name': 'Sippar',
      'Babylonian.Kutha.name': 'Kutha',
      'Babylonian.Jemdet Nasr.name': 'Jemdet Nasr',
      'Babylonian.Kish.name': 'Kish',
      'Babylonian.Borsippa.name': 'Borsippa',
      'Babylonian.Mashkan-shapir.name': 'Mashkan-shapir',
      'Babylonian.Dilbat.name': 'Dilbat',
      'Babylonian.Nippur.name': 'Nippur',
      'Babylonian.Marad.name': 'Marad',
      'Babylonian.Adab.name': 'Adab',
      'Babylonian.Isin.name': 'Isin',
      'Babylonian.Kisurra.name': 'Kisurra',
      'Babylonian.Shuruppak.name': 'Shuruppak',
      'Babylonian.Bad-tibira.name': 'Bad-tibira',
      'Babylonian.Zabalam.name': 'Zabalam',
      'Babylonian.Umma.name': 'Umma',
      'Babylonian.Girsu.name': 'Girsu',
      'Babylonian.Lagash.name': 'Lagash',
      'Babylonian.Urum.name': 'Urum',
      'Babylonian.Uruk.name': 'Uruk',
      'Babylonian.Larsa.name': 'Larsa',
      'Babylonian.Ur.name': 'Ur',
      'Babylonian.Kuara.name': 'Kuara',
      'Babylonian.Eridu.name': 'Eridu',
      'Babylonian.Akshak.name': 'Akshak',
      'Babylonian.Akkad.name': 'Akkad',

      // Chinese
      'Chinese.Beijing.name': 'Beijing',
      'Chinese.Shanghai.name': 'Shanghai',
      'Chinese.Chongqing.name': 'Chongqing',
      'Chinese.Tianjin.name': 'Tianjin',
      'Chinese.Guangzhou.name': 'Guangzhou',
      'Chinese.Shenzhen.name': 'Shenzhen',
      'Chinese.Chengdu.name': 'Chengdu',
      'Chinese.Nanjing.name': 'Nanjing',
      'Chinese.Wuhan.name': 'Wuhan',
      "Chinese.Xi'an.name": "Xi'an",
      'Chinese.Hangzhou.name': 'Hangzhou',
      'Chinese.Dongguan.name': 'Dongguan',
      'Chinese.Foshan.name': 'Foshan',
      'Chinese.Shenyang.name': 'Shenyang',
      'Chinese.Harbin.name': 'Harbin',
      'Chinese.Qingdao.name': 'Qingdao',
      'Chinese.Dalian.name': 'Dalian',
      'Chinese.Jinan.name': 'Jinan',
      'Chinese.Zhengzhou.name': 'Zhengzhou',
      'Chinese.Changsha.name': 'Changsha',
      'Chinese.Kunming.name': 'Kunming',
      'Chinese.Changchun.name': 'Changchun',
      'Chinese.Ürümqi.name': 'Ürümqi',
      'Chinese.Shantou.name': 'Shantou',
      'Chinese.Hefei.name': 'Hefei',
      'Chinese.Shijiazhuang.name': 'Shijiazhuang',
      'Chinese.Ningbo.name': 'Ningbo',
      'Chinese.Taiyuan.name': 'Taiyuan',
      'Chinese.Nanning.name': 'Nanning',
      'Chinese.Xiamen.name': 'Xiamen',
      'Chinese.Fuzhou.name': 'Fuzhou',
      'Chinese.Changzhou.name': 'Changzhou',
      'Chinese.Wenzhou.name': 'Wenzhou',
      'Chinese.Nanchang.name': 'Nanchang',
      'Chinese.Tangshan.name': 'Tangshan',
      'Chinese.Guiyang.name': 'Guiyang',
      'Chinese.Wuxi.name': 'Wuxi',
      'Chinese.Lanzhou.name': 'Lanzhou',
      'Chinese.Zhongshan.name': 'Zhongshan',
      'Chinese.Handan.name': 'Handan',
      "Chinese.Huai'an.name": "Huai'an",
      'Chinese.Weifang.name': 'Weifang',
      'Chinese.Zibo.name': 'Zibo',
      'Chinese.Shaoxing.name': 'Shaoxing',
      'Chinese.Yantai.name': 'Yantai',
      'Chinese.Huizhou.name': 'Huizhou',
      'Chinese.Luoyang.name': 'Luoyang',
      'Chinese.Nantong.name': 'Nantong',
      'Chinese.Baotou.name': 'Baotou',
      'Chinese.Liuzhou.name': 'Liuzhou',

    // Egyptian
    'Egyptian.Cairo.name': 'Cairo',
    'Egyptian.Alexandria.name': 'Alexandria', // Already exists in American list
    'Egyptian.Gizeh.name': 'Gizeh',
    'Egyptian.Shubra El-Kheima.name': 'Shubra El-Kheima',
    'Egyptian.Port Said.name': 'Port Said',
    'Egyptian.Suez.name': 'Suez',
    'Egyptian.Luxor.name': 'Luxor',
    'Egyptian.al-Mansura.name': 'al-Mansura',
    'Egyptian.El-Mahalla El-Kubra.name': 'El-Mahalla El-Kubra',
    'Egyptian.Tanta.name': 'Tanta',
    'Egyptian.Asyut.name': 'Asyut',
    'Egyptian.Ismailia.name': 'Ismailia',
    'Egyptian.Fayyum.name': 'Fayyum',
    'Egyptian.Zagazig.name': 'Zagazig',
    'Egyptian.Aswan.name': 'Aswan',
    'Egyptian.Damietta.name': 'Damietta',
    'Egyptian.Damanhur.name': 'Damanhur',
    'Egyptian.al-Minya.name': 'al-Minya',
    'Egyptian.Beni Suef.name': 'Beni Suef',
    'Egyptian.Qena.name': 'Qena',
    'Egyptian.Sohag.name': 'Sohag',
    'Egyptian.Hurghada.name': 'Hurghada',
    'Egyptian.6th of October City.name': '6th of October City',
    'Egyptian.Shibin El Kom.name': 'Shibin El Kom',
    'Egyptian.Banha.name': 'Banha',
    'Egyptian.Kafr el-Sheikh.name': 'Kafr el-Sheikh',
    'Egyptian.Arish.name': 'Arish',
    'Egyptian.Mallawi.name': 'Mallawi',
    'Egyptian.10th of Ramadan City.name': '10th of Ramadan City',
    'Egyptian.Bilbais.name': 'Bilbais',
    'Egyptian.Marsa Matruh.name': 'Marsa Matruh',
    'Egyptian.Idfu.name': 'Idfu',
    'Egyptian.Mit Ghamr.name': 'Mit Ghamr',
    'Egyptian.Al-Hamidiyya.name': 'Al-Hamidiyya',
    'Egyptian.Desouk.name': 'Desouk',
    'Egyptian.Qalyub.name': 'Qalyub',
    'Egyptian.Abu Kabir.name': 'Abu Kabir',
    'Egyptian.Kafr el-Dawwar.name': 'Kafr el-Dawwar',
    'Egyptian.Girga.name': 'Girga',
    'Egyptian.Akhmim.name': 'Akhmim',
    'Egyptian.Matarey.name': 'Matarey',

    // English
    'English.London.name': 'London',
    'English.Birmingham.name': 'Birmingham',
    'English.Liverpool.name': 'Liverpool',
    'English.Leeds.name': 'Leeds',
    'English.Sheffield.name': 'Sheffield',
    'English.Bristol.name': 'Bristol',
    'English.Manchester.name': 'Manchester',
    'English.Leicester.name': 'Leicester',
    'English.Coventry.name': 'Coventry',
    'English.Kingston upon Hull.name': 'Kingston upon Hull',
    'English.Stoke-on-Trent.name': 'Stoke-on-Trent',
    'English.Wolverhampton.name': 'Wolverhampton',
    'English.Nottingham.name': 'Nottingham',
    'English.Plymouth.name': 'Plymouth',
    'English.Southampton.name': 'Southampton',
    'English.Reading.name': 'Reading',
    'English.Derby.name': 'Derby',
    'English.Dudley.name': 'Dudley',
    'English.Newcastle upon Tyne.name': 'Newcastle upon Tyne',
    'English.Northampton.name': 'Northampton',
    'English.Portsmouth.name': 'Portsmouth',
    'English.Luton.name': 'Luton',
    'English.Preston.name': 'Preston',
    'English.Sunderland.name': 'Sunderland',
    'English.Norwich.name': 'Norwich',
    'English.Walsall.name': 'Walsall',
    'English.Bournemouth.name': 'Bournemouth',
    'English.Southend-on-Sea.name': 'Southend-on-Sea',
    'English.Swindon.name': 'Swindon',
    'English.Huddersfield.name': 'Huddersfield',
    'English.Poole.name': 'Poole',
    'English.Oxford.name': 'Oxford',
    'English.Middlesbrough.name': 'Middlesbrough',
    'English.Blackpool.name': 'Blackpool',
    'English.Oldbury.name': 'Oldbury',
    'English.Boldon.name': 'Boldon',
    'English.Ipswich.name': 'Ipswich',
    'English.York.name': 'York',
    'English.West Bromwich.name': 'West Bromwich',
    'English.Peterborough.name': 'Peterborough',
    'English.Stockport.name': 'Stockport',
    'English.Brighton.name': 'Brighton',
    'English.Slough.name': 'Slough',
    'English.Gloucester.name': 'Gloucester',
    'English.Rotherham.name': 'Rotherham',
    'English.Cambridge.name': 'Cambridge',
    'English.Exeter.name': 'Exeter',
    'English.Eastbourne.name': 'Eastbourne',
    'English.Sutton Coldfield.name': 'Sutton Coldfield',
    'English.Blackburn.name': 'Blackburn',
    'English.Colchester.name': 'Colchester',
    'English.Oldham.name': 'Oldham',
    'English.St Helens.name': 'St Helens',
    'English.Woking.name': 'Woking',
    'English.Chesterfield.name': 'Chesterfield',
    'English.Crawley.name': 'Crawley',

    // French
    'French.Paris.name': 'Paris',
    'French.Marseille.name': 'Marseille',
    'French.Lyon.name': 'Lyon',
    'French.Toulouse.name': 'Toulouse',
    'French.Nice.name': 'Nice',
    'French.Nantes.name': 'Nantes',
    'French.Strasbourg.name': 'Strasbourg',
    'French.Montpellier.name': 'Montpellier',
    'French.Bordeaux.name': 'Bordeaux',
    'French.Lille.name': 'Lille',
    'French.Rennes.name': 'Rennes',
    'French.Reims.name': 'Reims',
    'French.Le Havre.name': 'Le Havre',
    'French.Saint-Étienne.name': 'Saint-Étienne',
    'French.Toulon.name': 'Toulon',
    'French.Grenoble.name': 'Grenoble',
    'French.Dijon.name': 'Dijon',
    'French.Nîmes.name': 'Nîmes',
    'French.Angers.name': 'Angers',
    'French.Villeurbanne.name': 'Villeurbanne',
    'French.Le Mans.name': 'Le Mans',
    'French.Saint-Denis.name': 'Saint-Denis',
    'French.Aix-en-Provence.name': 'Aix-en-Provence',
    'French.Clermont-Ferrand.name': 'Clermont-Ferrand',
    'French.Brest.name': 'Brest',
    'French.Limoges.name': 'Limoges',
    'French.Tours.name': 'Tours',
    'French.Amiens.name': 'Amiens',
    'French.Perpignan.name': 'Perpignan',
    'French.Metz.name': 'Metz',
    'French.Besançon.name': 'Besançon',
    'French.Boulogne-Billancourt.name': 'Boulogne-Billancourt',
    'French.Orléans.name': 'Orléans',
    'French.Mulhouse.name': 'Mulhouse',
    'French.Rouen.name': 'Rouen',
    'French.Caen.name': 'Caen',
    'French.Argenteuil.name': 'Argenteuil',
    'French.Saint-Paul.name': 'Saint-Paul',
    'French.Montreuil.name': 'Montreuil',
    'French.Nancy.name': 'Nancy',
    'French.Roubaix.name': 'Roubaix',
    'French.Tourcoing.name': 'Tourcoing',
    'French.Nanterre.name': 'Nanterre',
    'French.Avignon.name': 'Avignon',
    'French.Vitry-sur-Seine.name': 'Vitry-sur-Seine',
    'French.Créteil.name': 'Créteil',
    'French.Dunkirk.name': 'Dunkirk',
    'French.Poitiers.name': 'Poitiers',
    'French.Asnières-sur-Seine.name': 'Asnières-sur-Seine',
    'French.Courbevoie.name': 'Courbevoie',
    'French.Versailles.name': 'Versailles',
    'French.Colombes.name': 'Colombes',
    'French.Fort-de-France.name': 'Fort-de-France',
    'French.Aulnay-sous-Bois.name': 'Aulnay-sous-Bois',
    'French.Saint-Pierre.name': 'Saint-Pierre',
    'French.Rueil-Malmaison.name': 'Rueil-Malmaison',
    'French.Pau.name': 'Pau',
    'French.Aubervilliers.name': 'Aubervilliers',
    'French.Le Tampon.name': 'Le Tampon',
    'French.Champigny-sur-Marne.name': 'Champigny-sur-Marne',
    'French.Antibes.name': 'Antibes',
    'French.Béziers.name': 'Béziers',
    'French.La Rochelle.name': 'La Rochelle',
    'French.Saint-Maur-des-Fossés.name': 'Saint-Maur-des-Fossés',
    'French.Cannes.name': 'Cannes',
    'French.Calais.name': 'Calais',
    'French.Saint-Nazaire.name': 'Saint-Nazaire',
    'French.Mérignac.name': 'Mérignac',
    'French.Drancy.name': 'Drancy',
    'French.Colmar.name': 'Colmar',
    'French.Ajaccio.name': 'Ajaccio',
    'French.Bourges.name': 'Bourges',
    'French.Issy-les-Moulineaux.name': 'Issy-les-Moulineaux',
    'French.Levallois-Perret.name': 'Levallois-Perret',
    'French.La Seyne-sur-Mer.name': 'La Seyne-sur-Mer',
    'French.Quimper.name': 'Quimper',
    'French.Noisy-le-Grand.name': 'Noisy-le-Grand',
    "French.Villeneuve-d'Ascq.name": "Villeneuve-d'Ascq",
    'French.Neuilly-sur-Seine.name': 'Neuilly-sur-Seine',
    'French.Valence.name': 'Valence',
    'French.Antony.name': 'Antony',
    'French.Cergy.name': 'Cergy',
    'French.Vénissieux.name': 'Vénissieux',
    'French.Pessac.name': 'Pessac',
    'French.Troyes.name': 'Troyes',
    'French.Clichy.name': 'Clichy',
    'French.Ivry-sur-Seine.name': 'Ivry-sur-Seine',
    'French.Chambéry.name': 'Chambéry',
    'French.Lorient.name': 'Lorient',
    'French.Les Abymes.name': 'Les Abymes',
    'French.Montauban.name': 'Montauban',
    'French.Sarcelles.name': 'Sarcelles',
    'French.Niort.name': 'Niort',
    'French.Villejuif.name': 'Villejuif',
    'French.Saint-André.name': 'Saint-André',
    'French.Hyères.name': 'Hyères',
    'French.Saint-Quentin.name': 'Saint-Quentin',
    'French.Beauvais.name': 'Beauvais',
    'French.Épinay-sur-Seine.name': 'Épinay-sur-Seine',
    'French.Cayenne.name': 'Cayenne',
    'French.Maisons-Alfort.name': 'Maisons-Alfort',
    'French.Cholet.name': 'Cholet',
    'French.Meaux.name': 'Meaux',
    'French.Chelles.name': 'Chelles',
    'French.Pantin.name': 'Pantin',
    'French.Évry.name': 'Évry',
    'French.Fontenay-sous-Bois.name': 'Fontenay-sous-Bois',
    'French.Fréjus.name': 'Fréjus',
    'French.Vannes.name': 'Vannes',
    'French.Bondy.name': 'Bondy',
    'French.Le Blanc-Mesnil.name': 'Le Blanc-Mesnil',
    'French.La Roche-sur-Yon.name': 'La Roche-sur-Yon',
    'French.Saint-Louis.name': 'Saint-Louis',
    'French.Arles.name': 'Arles',
    'French.Clamart.name': 'Clamart',
    'French.Narbonne.name': 'Narbonne',
    'French.Annecy.name': 'Annecy',
    'French.Sartrouville.name': 'Sartrouville',
    'French.Grasse.name': 'Grasse',
    'French.Laval.name': 'Laval',
    'French.Belfort.name': 'Belfort',
    'French.Bobigny.name': 'Bobigny',
    'French.Évreux.name': 'Évreux',
    'French.Vincennes.name': 'Vincennes',
    'French.Montrouge.name': 'Montrouge',
    'French.Sevran.name': 'Sevran',
    'French.Albi.name': 'Albi',
    'French.Charleville-Mézières.name': 'Charleville-Mézières',
    'French.Suresnes.name': 'Suresnes',
    'French.Martigues.name': 'Martigues',
    'French.Corbeil-Essonnes.name': 'Corbeil-Essonnes',
    'French.Saint-Ouen.name': 'Saint-Ouen',
    'French.Bayonne.name': 'Bayonne',
    'French.Cagnes-sur-Mer.name': 'Cagnes-sur-Mer',
    'French.Brive-la-Gaillarde.name': 'Brive-la-Gaillarde',
    'French.Carcassonne.name': 'Carcassonne',
    'French.Massy.name': 'Massy',
    'French.Blois.name': 'Blois',
    'French.Aubagne.name': 'Aubagne',
    'French.Saint-Brieuc.name': 'Saint-Brieuc',
    'French.Châteauroux.name': 'Châteauroux',
    'French.Chalon-sur-Saône.name': 'Chalon-sur-Saône',
    'French.Mantes-la-Jolie.name': 'Mantes-la-Jolie',
    'French.Meudon.name': 'Meudon',
    'French.Saint-Malo.name': 'Saint-Malo',
    'French.Châlons-en-Champagne.name': 'Châlons-en-Champagne',
    'French.Alfortville.name': 'Alfortville',
    'French.Sète.name': 'Sète',
    'French.Salon-de-Provence.name': 'Salon-de-Provence',
    'French.Vaulx-en-Velin.name': 'Vaulx-en-Velin',
    'French.Puteaux.name': 'Puteaux',
    'French.Rosny-sous-Bois.name': 'Rosny-sous-Bois',
    'French.Saint-Herblain.name': 'Saint-Herblain',
    'French.Gennevilliers.name': 'Gennevilliers',
    'French.Le Cannet.name': 'Le Cannet',
    'French.Livry-Gargan.name': 'Livry-Gargan',
    'French.Saint-Priest.name': 'Saint-Priest',
    'French.Istres.name': 'Istres',
    'French.Valenciennes.name': 'Valenciennes',
    'French.Choisy-le-Roi.name': 'Choisy-le-Roi',
    'French.Caluire-et-Cuire.name': 'Caluire-et-Cuire',
    'French.Boulogne-sur-Mer.name': 'Boulogne-sur-Mer',
    'French.Bastia.name': 'Bastia',
    'French.Angoulême.name': 'Angoulême',
    'French.Garges-lès-Gonesse.name': 'Garges-lès-Gonesse',
    'French.Castres.name': 'Castres',
    'French.Thionville.name': 'Thionville',
    'French.Wattrelos.name': 'Wattrelos',
    'French.Talence.name': 'Talence',
    'French.Saint-Laurent-du-Maroni.name': 'Saint-Laurent-du-Maroni',
    'French.Douai.name': 'Douai',
    'French.Noisy-le-Sec.name': 'Noisy-le-Sec',
    'French.Tarbes.name': 'Tarbes',
    'French.Arras.name': 'Arras',
    'French.Alès.name': 'Alès',
    'French.La Courneuve.name': 'La Courneuve',
    'French.Bourg-en-Bresse.name': 'Bourg-en-Bresse',
    'French.Compiègne.name': 'Compiègne',
    'French.Gap.name': 'Gap',
    'French.Melun.name': 'Melun',
    'French.Le Lamentin.name': 'Le Lamentin',
    'French.Rezé.name': 'Rezé',
    'French.Saint-Germain-en-Laye.name': 'Saint-Germain-en-Laye',
    'French.Marcq-en-Barœul.name': 'Marcq-en-Barœul',
    'French.Gagny.name': 'Gagny',
    'French.Anglet.name': 'Anglet',
    'French.Draguignan.name': 'Draguignan',
    'French.Chartres.name': 'Chartres',
    'French.Bron.name': 'Bron',
    'French.Bagneux.name': 'Bagneux',
    'French.Colomiers.name': 'Colomiers',
    "French.Saint-Martin-d'Hères.name": "Saint-Martin-d'Hères",
    'French.Pontault-Combault.name': 'Pontault-Combault',
    'French.Montluçon.name': 'Montluçon',
    'French.Joué-lès-Tours.name': 'Joué-lès-Tours',
    'French.Saint-Joseph.name': 'Saint-Joseph',
    'French.Poissy.name': 'Poissy',
    'French.Savigny-sur-Orge.name': 'Savigny-sur-Orge',
    'French.Cherbourg-Octeville.name': 'Cherbourg-Octeville',
    'French.Montélimar.name': 'Montélimar',
    'French.Villefranche-sur-Saône.name': 'Villefranche-sur-Saône',
    'French.Stains.name': 'Stains',
    'French.Saint-Benoît.name': 'Saint-Benoît',
    'French.Bagnolet.name': 'Bagnolet',
    'French.Châtillon.name': 'Châtillon',
    'French.Le Port.name': 'Le Port',
    'French.Sainte-Geneviève-des-Bois.name': 'Sainte-Geneviève-des-Bois',
    'French.Échirolles.name': 'Échirolles',
    'French.Roanne.name': 'Roanne',
    'French.Villepinte.name': 'Villepinte',
    'French.Saint-Chamond.name': 'Saint-Chamond',
    'French.Conflans-Sainte-Honorine.name': 'Conflans-Sainte-Honorine',
    'French.Auxerre.name': 'Auxerre',
    'French.Nevers.name': 'Nevers',
    'French.Neuilly-sur-Marne.name': 'Neuilly-sur-Marne',
    'French.La Ciotat.name': 'La Ciotat',
    'French.Tremblay-en-France.name': 'Tremblay-en-France',
    'French.Thonon-les-Bains.name': 'Thonon-les-Bains',
    'French.Vitrolles.name': 'Vitrolles',
    'French.Haguenau.name': 'Haguenau',
    'French.Six-Fours-les-Plages.name': 'Six-Fours-les-Plages',
    'French.Agen.name': 'Agen',
    'French.Creil.name': 'Creil',
    'French.Annemasse.name': 'Annemasse',
    'French.Saint-Raphaël.name': 'Saint-Raphaël',
    'French.Marignane.name': 'Marignane',
    'French.Romans-sur-Isère.name': 'Romans-sur-Isère',
    'French.Montigny-le-Bretonneux.name': 'Montigny-le-Bretonneux',
    'French.Le Perreux-sur-Marne.name': 'Le Perreux-sur-Marne',
    'French.Franconville.name': 'Franconville',
    'French.Mâcon.name': 'Mâcon',
    'French.Saint-Leu.name': 'Saint-Leu',
    'French.Cambrai.name': 'Cambrai',
    'French.Châtenay-Malabry.name': 'Châtenay-Malabry',
    'French.Sainte-Marie.name': 'Sainte-Marie',
    'French.Villeneuve-Saint-Georges.name': 'Villeneuve-Saint-Georges',
    'French.Houilles.name': 'Houilles',
    'French.Épinal.name': 'Épinal',
    'French.Lens.name': 'Lens',
    'French.Liévin.name': 'Liévin',
    'French.Les Mureaux.name': 'Les Mureaux',
    'French.Schiltigheim.name': 'Schiltigheim',
    'French.La Possession.name': 'La Possession',
    'French.Meyzieu.name': 'Meyzieu',
    'French.Dreux.name': 'Dreux',
    'French.Nogent-sur-Marne.name': 'Nogent-sur-Marne',
    'French.Plaisir.name': 'Plaisir',
    'French.Mont-de-Marsan.name': 'Mont-de-Marsan',
    'French.Palaiseau.name': 'Palaiseau',
    'French.Châtellerault.name': 'Châtellerault',
    'French.Goussainville.name': 'Goussainville',
    "French.L'Haÿ-les-Roses.name": "L'Haÿ-les-Roses",
    'French.Viry-Châtillon.name': 'Viry-Châtillon',
    'French.Vigneux-sur-Seine.name': 'Vigneux-sur-Seine',
    'French.Chatou.name': 'Chatou',
    'French.Trappes.name': 'Trappes',
    'French.Clichy-sous-Bois.name': 'Clichy-sous-Bois',
    'French.Rillieux-la-Pape.name': 'Rillieux-la-Pape',
    "French.Villenave-'Ornon.name": "Villenave-'Ornon",
    'French.Maubeuge.name': 'Maubeuge',
    'French.Charenton-le-Pont.name': 'Charenton-le-Pont',
    'French.Malakoff.name': 'Malakoff',
    'French.Matoury.name': 'Matoury',
    'French.Dieppe.name': 'Dieppe',
    'French.Athis-Mons.name': 'Athis-Mons',
    'French.Savigny-le-Temple.name': 'Savigny-le-Temple',
    'French.Périgueux.name': 'Périgueux',
    'French.Baie-Mahault.name': 'Baie-Mahault',
    'French.Vandoeuvre-lès-Nancy.name': 'Vandoeuvre-lès-Nancy',
    'French.Pontoise.name': 'Pontoise',
    'French.Aix-les-Bains.name': 'Aix-les-Bains',
    'French.Cachan.name': 'Cachan',
    'French.Vienne.name': 'Vienne',
    'French.Thiais.name': 'Thiais',
    'French.Orange.name': 'Orange',
    'French.Saint-Médard-en-Jalles.name': 'Saint-Médard-en-Jalles',
    'French.Villemomble.name': 'Villemomble',
    'French.Saint-Cloud.name': 'Saint-Cloud',
    'French.Saint-Laurent-du-Var.name': 'Saint-Laurent-du-Var',
    'French.Yerres.name': 'Yerres',
    'French.Saint-Étienne-du-Rouvray.name': 'Saint-Étienne-du-Rouvray',
    'French.Sotteville-lès-Rouen.name': 'Sotteville-lès-Rouen',
    'French.Draveil.name': 'Draveil',
    'French.Le Chesnay.name': 'Le Chesnay',
    'French.Bois-Colombes.name': 'Bois-Colombes',
    'French.Le Plessis-Robinson.name': 'Le Plessis-Robinson',
    'French.La Garenne-Colombes.name': 'La Garenne-Colombes',
    'French.Lambersart.name': 'Lambersart',
    'French.Soissons.name': 'Soissons',
    'French.Pierrefitte-sur-Seine.name': 'Pierrefitte-sur-Seine',
    'French.Carpentras.name': 'Carpentras',
    'French.Villiers-sur-Marne.name': 'Villiers-sur-Marne',
    'French.Vanves.name': 'Vanves',
    'French.Menton.name': 'Menton',
    'French.Bergerac.name': 'Bergerac',
    'French.Ermont.name': 'Ermont',
    'French.Bezons.name': 'Bezons',
    'French.Grigny.name': 'Grigny',
    'French.Guyancourt.name': 'Guyancourt',
    'French.Saumur.name': 'Saumur',
    'French.Herblay.name': 'Herblay',
    'French.Ris-Orangis.name': 'Ris-Orangis',
    'French.Villiers-le-Bel.name': 'Villiers-le-Bel',
    'French.Bourgoin-Jallieu.name': 'Bourgoin-Jallieu',
    'French.Vierzon.name': 'Vierzon',
    'French.Le Gosier.name': 'Le Gosier',
    'French.Décines-Charpieu.name': 'Décines-Charpieu',
    'French.Hénin-Beaumont.name': 'Hénin-Beaumont',
    'French.Fresnes.name': 'Fresnes',
    'French.Aurillac.name': 'Aurillac',
    'French.Sannois.name': 'Sannois',
    'French.Vallauris.name': 'Vallauris',
    'French.Illkirch-Graffenstaden.name': 'Illkirch-Graffenstaden',
    'French.Alençon.name': 'Alençon',
    'French.Élancourt.name': 'Élancourt',
    'French.Tournefeuille.name': 'Tournefeuille',
    'French.Bègles.name': 'Bègles',
    'French.Gonesse.name': 'Gonesse',
    'French.Oullins.name': 'Oullins',
    'French.Brunoy.name': 'Brunoy',
    'French.Taverny.name': 'Taverny',
    'French.Armentières.name': 'Armentières',
    'French.Montfermeil.name': 'Montfermeil',
    'French.Rambouillet.name': 'Rambouillet',
    'French.Villeparisis.name': 'Villeparisis',
    'French.Le Kremlin-Bicêtre.name': 'Le Kremlin-Bicêtre',
    'French.Sucy-en-Brie.name': 'Sucy-en-Brie',
    'French.Kourou.name': 'Kourou',
    'French.Montbéliard.name': 'Montbéliard',
    'French.Romainville.name': 'Romainville',
    'French.Cavaillon.name': 'Cavaillon',
    'French.Saint-Dizier.name': 'Saint-Dizier',
    'French.Brétigny-sur-Orge.name': 'Brétigny-sur-Orge',
    'French.Saint-Sébastien-sur-Loire.name': 'Saint-Sébastien-sur-Loire',
    'French.Saintes.name': 'Saintes',
    'French.La Teste-de-Buch.name': 'La Teste-de-Buch',
    'French.Villeneuve-la-Garenne.name': 'Villeneuve-la-Garenne',
    'French.Béthune.name': 'Béthune',
    'French.Bussy-Saint-Georges.name': 'Bussy-Saint-Georges',
    'French.Vichy.name': 'Vichy',
    'French.La Garde.name': 'La Garde',
    'French.Agde.name': 'Agde',
    'French.Laon.name': 'Laon',
    'French.Sens.name': 'Sens',
    'French.Lunel.name': 'Lunel',
    'French.Miramas.name': 'Miramas',
    'French.Biarritz.name': 'Biarritz',
    'French.Le Grand-Quevilly.name': 'Le Grand-Quevilly',
    'French.Orvault.name': 'Orvault',
    'French.Les Ulis.name': 'Les Ulis',
    'French.Champs-sur-Marne.name': 'Champs-sur-Marne',
    'French.Rochefort.name': 'Rochefort',
    'French.Muret.name': 'Muret',
    'French.Sainte-Anne.name': 'Sainte-Anne',
    'French.Eaubonne.name': 'Eaubonne',
    'French.Étampes.name': 'Étampes',
    'French.Gradignan.name': 'Gradignan',
    'French.Vernon.name': 'Vernon',
    'French.Petit-Bourg.name': 'Petit-Bourg',
    'French.Libourne.name': 'Libourne',
    'French.Abbeville.name': 'Abbeville',
    'French.Rodez.name': 'Rodez',
    "French.Saint-Ouen-l'Aumône.name": "Saint-Ouen-l'Aumône",
    'French.Torcy.name': 'Torcy',
    'French.Maisons-Laffitte.name': 'Maisons-Laffitte',
    'French.Montgeron.name': 'Montgeron',
    'French.Villeneuve-sur-Lot.name': 'Villeneuve-sur-Lot',
    'French.Cormeilles-en-Parisis.name': 'Cormeilles-en-Parisis',
    'French.Épernay.name': 'Épernay',
    'French.Sèvres.name': 'Sèvres',
    'French.Dole.name': 'Dole',
    'French.Le Robert.name': 'Le Robert',
    'French.Le Bouscat.name': 'Le Bouscat',
    'French.Blagnac.name': 'Blagnac',
    'French.Frontignan.name': 'Frontignan',
    'French.Cenon.name': 'Cenon',
    'French.Mandelieu-la-Napoule.name': 'Mandelieu-la-Napoule',
    'French.Vertou.name': 'Vertou',
    'French.Les Lilas.name': 'Les Lilas',
    'French.Bruay-la-Buissière.name': 'Bruay-la-Buissière',
    'French.Les Pavillons-sous-Bois.name': 'Les Pavillons-sous-Bois',
    'French.Chaumont.name': 'Chaumont',
    'French.Roissy-en-Brie.name': 'Roissy-en-Brie',
    'French.Le Moule.name': 'Le Moule',
    'French.Le Petit-Quevilly.name': 'Le Petit-Quevilly',
    'French.Manosque.name': 'Manosque',
    'French.Saint-Mandé.name': 'Saint-Mandé',
    'French.Fontenay-aux-Roses.name': 'Fontenay-aux-Roses',
    'French.Orly.name': 'Orly',
    'French.Le Creusot.name': 'Le Creusot',
    'French.Oyonnax.name': 'Oyonnax',
    'French.La Madeleine.name': 'La Madeleine',
    'French.Sainte-Suzanne.name': 'Sainte-Suzanne',
    'French.Millau.name': 'Millau',
    'French.Combs-la-Ville.name': 'Combs-la-Ville',
    'French.Fontaine.name': 'Fontaine',
    'French.Deuil-la-Barre.name': 'Deuil-la-Barre',
    'French.Coudekerque-Branche.name': 'Coudekerque-Branche',
    'French.Auch.name': 'Auch',
    'French.Lanester.name': 'Lanester',
    'French.Beaune.name': 'Beaune',
    'French.Montigny-lès-Metz.name': 'Montigny-lès-Metz',
    'French.Hazebrouck.name': 'Hazebrouck',
    'French.Longjumeau.name': 'Longjumeau',
    'French.Sainte-Foy-lès-Lyon.name': 'Sainte-Foy-lès-Lyon',
    'French.Forbach.name': 'Forbach',
    'French.Sarreguemines.name': 'Sarreguemines',
    'French.Mons-en-Barœul.name': 'Mons-en-Barœul',
    'French.La Valette-du-Var.name': 'La Valette-du-Var',
    'French.Hérouville-Saint-Clair.name': 'Hérouville-Saint-Clair',
    'French.Morsang-sur-Orge.name': 'Morsang-sur-Orge',
    'French.Grande-Synthe.name': 'Grande-Synthe',
    'French.La Celle-Saint-Cloud.name': 'La Celle-Saint-Cloud',
    'French.Lisieux.name': 'Lisieux',
    'French.Croix.name': 'Croix',
    'French.Dammarie-les-Lys.name': 'Dammarie-les-Lys',
    'French.Vélizy-Villacoublay.name': 'Vélizy-Villacoublay',
    'French.Wasquehal.name': 'Wasquehal',
    'French.Saint-Gratien.name': 'Saint-Gratien',
    'French.Halluin.name': 'Halluin',
    'French.Neuilly-Plaisance.name': 'Neuilly-Plaisance',
    'French.Montmorency.name': 'Montmorency',
    'French.Dax.name': 'Dax',
    'French.Lagny-sur-Marne.name': 'Lagny-sur-Marne',
    'French.Le Mée-sur-Seine.name': 'Le Mée-sur-Seine',
    'French.Saint-Genis-Laval.name': 'Saint-Genis-Laval',
    'French.Fleury-les-Aubrais.name': 'Fleury-les-Aubrais',
    'French.Loos.name': 'Loos',
    'French.Gif-sur-Yvette.name': 'Gif-sur-Yvette',
    'French.Denain.name': 'Denain',
    'French.Saint-Dié-des-Vosges.name': 'Saint-Dié-des-Vosges',
    'French.Sainte-Rose.name': 'Sainte-Rose',
    'French.Saint-Michel-sur-Orge.name': 'Saint-Michel-sur-Orge',

    // German
    'German.Berlin.name': 'Berlin',
    'German.Hamburg.name': 'Hamburg',
    'German.Munich.name': 'Munich',
    'German.Cologne.name': 'Cologne',
    'German.Frankfurt.name': 'Frankfurt',
    'German.Stuttgart.name': 'Stuttgart',
    'German.Düsseldorf.name': 'Düsseldorf',
    'German.Dortmund.name': 'Dortmund',
    'German.Essen.name': 'Essen',
    'German.Leipzig.name': 'Leipzig',
    'German.Bremen.name': 'Bremen',
    'German.Dresden.name': 'Dresden',
    'German.Hanover.name': 'Hanover',
    'German.Nuremberg.name': 'Nuremberg',
    'German.Duisburg.name': 'Duisburg',
    'German.Bochum.name': 'Bochum',
    'German.Wuppertal.name': 'Wuppertal',
    'German.Bielefeld.name': 'Bielefeld',
    'German.Bonn.name': 'Bonn',
    'German.Münster.name': 'Münster',
    'German.Karlsruhe.name': 'Karlsruhe',
    'German.Mannheim.name': 'Mannheim',
    'German.Augsburg.name': 'Augsburg',
    'German.Wiesbaden.name': 'Wiesbaden',
    'German.Gelsenkirchen.name': 'Gelsenkirchen',
    'German.Mönchengladbach.name': 'Mönchengladbach',
    'German.Braunschweig.name': 'Braunschweig',
    'German.Chemnitz.name': 'Chemnitz',
    'German.Kiel.name': 'Kiel',
    'German.Aachen.name': 'Aachen',
    'German.Halle.name': 'Halle',
    'German.Magdeburg.name': 'Magdeburg',
    'German.Freiburg.name': 'Freiburg',
    'German.Krefeld.name': 'Krefeld',
    'German.Lübeck.name': 'Lübeck',
    'German.Oberhausen.name': 'Oberhausen',
    'German.Erfurt.name': 'Erfurt',
    'German.Mainz.name': 'Mainz',
    'German.Rostock.name': 'Rostock',
    'German.Kassel.name': 'Kassel',
    'German.Hagen.name': 'Hagen',
    'German.Hamm.name': 'Hamm',
    'German.Saarbrücken.name': 'Saarbrücken',
    'German.Mülheim an der Ruhr.name': 'Mülheim an der Ruhr',
    'German.Potsdam.name': 'Potsdam',
    'German.Ludwigshafen am Rhein.name': 'Ludwigshafen am Rhein',
    'German.Oldenburg.name': 'Oldenburg',
    'German.Leverkusen.name': 'Leverkusen',
    'German.Osnabrück.name': 'Osnabrück',
    'German.Solingen.name': 'Solingen',

    // Greek
    'Greek.Athens.name': 'Athens',
    'Greek.Thessaloniki.name': 'Thessaloniki',
    'Greek.Patras.name': 'Patras',
    'Greek.Piraeus.name': 'Piraeus',
    'Greek.Larissa.name': 'Larissa',
    'Greek.Heraklion.name': 'Heraklion',
    'Greek.Peristeri.name': 'Peristeri',
    'Greek.Kallithea.name': 'Kallithea',
    'Greek.Acharnes.name': 'Acharnes',
    'Greek.Kalamaria.name': 'Kalamaria',
    'Greek.Nikaia.name': 'Nikaia',
    'Greek.Glyfada.name': 'Glyfada',
    'Greek.Volos.name': 'Volos',
    'Greek.Ilio.name': 'Ilio',
    'Greek.Ilioupoli.name': 'Ilioupoli',
    'Greek.Keratsini.name': 'Keratsini',
    'Greek.Evosmos.name': 'Evosmos',
    'Greek.Chalandri.name': 'Chalandri',
    'Greek.Nea Smyrni.name': 'Nea Smyrni',
    'Greek.Marousi.name': 'Marousi',
    'Greek.Agios Dimitrios.name': 'Agios Dimitrios',
    'Greek.Zografou.name': 'Zografou',
    'Greek.Egaleo.name': 'Egaleo',
    'Greek.Nea Ionia.name': 'Nea Ionia',
    'Greek.Ioannina.name': 'Ioannina',
    'Greek.Palaio Faliro.name': 'Palaio Faliro',
    'Greek.Korydallos.name': 'Korydallos',
    'Greek.Trikala.name': 'Trikala',
    'Greek.Vyronas.name': 'Vyronas',
    'Greek.Agia Paraskevi.name': 'Agia Paraskevi',
    'Greek.Galatsi.name': 'Galatsi',
    'Greek.Agrinio.name': 'Agrinio',
    'Greek.Chalcis.name': 'Chalcis',
    'Greek.Petroupoli.name': 'Petroupoli',
    'Greek.Serres.name': 'Serres',
    'Greek.Alexandroupoli.name': 'Alexandroupoli',
    'Greek.Xanthi.name': 'Xanthi',
    'Greek.Katerini.name': 'Katerini',
    'Greek.Kalamata.name': 'Kalamata',
    'Greek.Kavala.name': 'Kavala',
    'Greek.Chania.name': 'Chania',
    'Greek.Lamia.name': 'Lamia',
    'Greek.Komotini.name': 'Komotini',
    'Greek.Irakleio.name': 'Irakleio',
    'Greek.Rhodes.name': 'Rhodes',
    'Greek.Kifissia.name': 'Kifissia',
    'Greek.Stavroupoli.name': 'Stavroupoli',
    'Greek.Chaidari.name': 'Chaidari',
    'Greek.Drama.name': 'Drama',
    'Greek.Veria.name': 'Veria',
    'Greek.Alimos.name': 'Alimos',
    'Greek.Kozani.name': 'Kozani',
    'Greek.Polichni.name': 'Polichni',
    'Greek.Karditsa.name': 'Karditsa',
    'Greek.Sykies.name': 'Sykies',
    'Greek.Ampelokipoi.name': 'Ampelokipoi',
    'Greek.Pylaia.name': 'Pylaia',
    'Greek.Agioi Anargyroi.name': 'Agioi Anargyroi',
    'Greek.Argyroupoli.name': 'Argyroupoli',
    'Greek.Ano Liosia.name': 'Ano Liosia',
    'Greek.Rethymno.name': 'Rethymno',
    'Greek.Ptolemaida.name': 'Ptolemaida',
    'Greek.Tripoli.name': 'Tripoli',
    'Greek.Cholargos.name': 'Cholargos',
    'Greek.Vrilissia.name': 'Vrilissia',
    'Greek.Aspropyrgos.name': 'Aspropyrgos',
    'Greek.Corinth.name': 'Corinth',
    'Greek.Gerakas.name': 'Gerakas',
    'Greek.Metamorfosi.name': 'Metamorfosi',
    'Greek.Giannitsa.name': 'Giannitsa',
    'Greek.Voula.name': 'Voula',
    'Greek.Kamatero.name': 'Kamatero',
    'Greek.Mytilene.name': 'Mytilene',
    'Greek.Neapoli.name': 'Neapoli',
    'Greek.Eleftherio-Kordelio.name': 'Eleftherio-Kordelio',
    'Greek.Chios.name': 'Chios',
    'Greek.Agia Varvara.name': 'Agia Varvara',
    'Greek.Kaisariani.name': 'Kaisariani',
    'Greek.Nea Filadelfeia.name': 'Nea Filadelfeia',
    'Greek.Moschato.name': 'Moschato',
    'Greek.Perama.name': 'Perama',
    'Greek.Salamina.name': 'Salamina',
    'Greek.Eleusis.name': 'Eleusis',
    'Greek.Corfu.name': 'Corfu',
    'Greek.Pyrgos.name': 'Pyrgos',
    'Greek.Megara.name': 'Megara',
    'Greek.Kilkis.name': 'Kilkis',
    'Greek.Dafni.name': 'Dafni',
    'Greek.Thebes.name': 'Thebes',
    'Greek.Melissia.name': 'Melissia',
    'Greek.Argos.name': 'Argos',
    'Greek.Arta.name': 'Arta',
    'Greek.Artemida.name': 'Artemida',
    'Greek.Livadeia.name': 'Livadeia',
    'Greek.Pefki.name': 'Pefki',
    'Greek.Oraiokastro.name': 'Oraiokastro',
    'Greek.Aigio.name': 'Aigio',
    'Greek.Kos.name': 'Kos',
    'Greek.Koropi.name': 'Koropi',
    'Greek.Preveza.name': 'Preveza',
    'Greek.Naousa.name': 'Naousa',
    'Greek.Orestiada.name': 'Orestiada',
    'Greek.Peraia.name': 'Peraia',
    'Greek.Edessa.name': 'Edessa',
    'Greek.Florina.name': 'Florina',
    'Greek.Panorama.name': 'Panorama',
    'Greek.Nea Erythraia1.name': 'Nea Erythraia1',
    'Greek.Elliniko1.name': 'Elliniko1',
    'Greek.Amaliada.name': 'Amaliada',
    'Greek.Pallini.name': 'Pallini',
    'Greek.Sparta.name': 'Sparta',
    'Greek.Agios Ioannis Rentis.name': 'Agios Ioannis Rentis',
    'Greek.Thermi.name': 'Thermi',
    'Greek.Vari.name': 'Vari',
    'Greek.Nea Makri.name': 'Nea Makri',
    'Greek.Tavros.name': 'Tavros',
    'Greek.Alexandreia.name': 'Alexandreia',
    'Greek.Menemeni.name': 'Menemeni',
    'Greek.Paiania.name': 'Paiania',
    'Greek.Kalyvia Thorikou.name': 'Kalyvia Thorikou',
    'Greek.Nafplio.name': 'Nafplio',
    'Greek.Drapetsona.name': 'Drapetsona',
    'Greek.Efkarpia.name': 'Efkarpia',
    'Greek.Papagou.name': 'Papagou',
    'Greek.Nafpaktos.name': 'Nafpaktos',
    'Greek.Kastoria.name': 'Kastoria',
    'Greek.Grevena.name': 'Grevena',
    'Greek.Pefka.name': 'Pefka',
    'Greek.Nea Alikarnassos.name': 'Nea Alikarnassos',
    'Greek.Missolonghi.name': 'Missolonghi',
    'Greek.Gazi.name': 'Gazi',
    'Greek.Ierapetra.name': 'Ierapetra',
    'Greek.Kalymnos.name': 'Kalymnos',
    'Greek.Rafina.name': 'Rafina',
    'Greek.Loutraki.name': 'Loutraki',
    'Greek.Agios Nikolaos.name': 'Agios Nikolaos',
    'Greek.Ermoupoli.name': 'Ermoupoli',
    'Greek.Ialysos.name': 'Ialysos',
    'Greek.Mandra.name': 'Mandra',
    'Greek.Tyrnavos.name': 'Tyrnavos',
    'Greek.Glyka Nera.name': 'Glyka Nera',
    'Greek.Ymittos.name': 'Ymittos',
    'Greek.Neo Psychiko.name': 'Neo Psychiko',

    // Indian
    'Indian.Delhi.name': 'Delhi',
    'Indian.Mumbai.name': 'Mumbai',
    'Indian.Bangalore.name': 'Bangalore',
    'Indian.Hyderabad.name': 'Hyderabad',
    'Indian.Ahmedabad.name': 'Ahmedabad',
    'Indian.Chennai.name': 'Chennai',
    'Indian.Kolkata.name': 'Kolkata',
    'Indian.Surat.name': 'Surat',
    'Indian.Pune.name': 'Pune',
    'Indian.Jaipur.name': 'Jaipur',
    'Indian.Lucknow.name': 'Lucknow',
    'Indian.Kanpur.name': 'Kanpur',
    'Indian.Nagpur.name': 'Nagpur',
    'Indian.Indore.name': 'Indore',
    'Indian.Thane.name': 'Thane',
    'Indian.Bhopal.name': 'Bhopal',
    'Indian.Visakhapatnam.name': 'Visakhapatnam',
    'Indian.Pimpri-Chinchwad.name': 'Pimpri-Chinchwad',
    'Indian.Patna.name': 'Patna',
    'Indian.Vadodara.name': 'Vadodara',
    'Indian.Ghaziabad.name': 'Ghaziabad',
    'Indian.Ludhiana.name': 'Ludhiana',
    'Indian.Agra.name': 'Agra',
    'Indian.Nashik.name': 'Nashik',
    'Indian.Faridabad.name': 'Faridabad',
    'Indian.Meerut.name': 'Meerut',
    'Indian.Rajkot.name': 'Rajkot',
    'Indian.Kalyan-Dombivli.name': 'Kalyan-Dombivli',
    'Indian.Vasai-Virar.name': 'Vasai-Virar',
    'Indian.Varanasi.name': 'Varanasi',
    'Indian.Srinagar.name': 'Srinagar',
    'Indian.Aurangabad.name': 'Aurangabad',
    'Indian.Dhanbad.name': 'Dhanbad',
    'Indian.Amritsar.name': 'Amritsar',
    'Indian.Navi Mumbai.name': 'Navi Mumbai',
    'Indian.Allahabad.name': 'Allahabad',
    'Indian.Howrah.name': 'Howrah',
    'Indian.Ranchi.name': 'Ranchi',
    'Indian.Gwalior.name': 'Gwalior',
    'Indian.Jabalpur.name': 'Jabalpur',
    'Indian.Coimbatore.name': 'Coimbatore',
    'Indian.Vijayawada.name': 'Vijayawada',
    'Indian.Jodhpur.name': 'Jodhpur',
    'Indian.Madurai.name': 'Madurai',
    'Indian.Raipur.name': 'Raipur',
    'Indian.Kota.name': 'Kota',
    'Indian.Chandigarh.name': 'Chandigarh',
    'Indian.Guwahati.name': 'Guwahati',
    'Indian.Solapur.name': 'Solapur',
    'Indian.Hubli–Dharwad.name': 'Hubli–Dharwad',
    'Indian.Tiruchirappalli.name': 'Tiruchirappalli',
    'Indian.Bareilly.name': 'Bareilly',
    'Indian.Moradabad.name': 'Moradabad',
    'Indian.Mysore.name': 'Mysore',
    'Indian.Tiruppur.name': 'Tiruppur',
    'Indian.Gurgaon.name': 'Gurgaon',
    'Indian.Aligarh.name': 'Aligarh',
    'Indian.Jalandhar.name': 'Jalandhar',
    'Indian.Bhubaneswar.name': 'Bhubaneswar',
    'Indian.Salem.name': 'Salem',
    'Indian.Mira-Bhayandar.name': 'Mira-Bhayandar',
    'Indian.Warangal.name': 'Warangal',
    'Indian.Jalgaon.name': 'Jalgaon',
    'Indian.Guntur.name': 'Guntur',
    'Indian.Bhiwandi.name': 'Bhiwandi',
    'Indian.Saharanpur.name': 'Saharanpur',
    'Indian.Gorakhpur.name': 'Gorakhpur',
    'Indian.Bikaner.name': 'Bikaner',
    'Indian.Amravati.name': 'Amravati',
    'Indian.Noida.name': 'Noida',
    'Indian.Jamshedpur.name': 'Jamshedpur',
    'Indian.Bhilai.name': 'Bhilai',
    'Indian.Cuttack.name': 'Cuttack',
    'Indian.Firozabad.name': 'Firozabad',
    'Indian.Kochi.name': 'Kochi',
    'Indian.Nellore.name': 'Nellore',
    'Indian.Bhavnagar.name': 'Bhavnagar',
    'Indian.Dehradun.name': 'Dehradun',
    'Indian.Durgapur.name': 'Durgapur',
    'Indian.Asansol.name': 'Asansol',
    'Indian.Rourkela.name': 'Rourkela',
    'Indian.Nanded.name': 'Nanded',
    'Indian.Kolhapur.name': 'Kolhapur',
    'Indian.Ajmer.name': 'Ajmer',
    'Indian.Akola.name': 'Akola',
    'Indian.Gulbarga.name': 'Gulbarga',
    'Indian.Jamnagar.name': 'Jamnagar',
    'Indian.Ujjain.name': 'Ujjain',
    'Indian.Loni.name': 'Loni',
    'Indian.Siliguri.name': 'Siliguri',
    'Indian.Jhansi.name': 'Jhansi',
    'Indian.Ulhasnagar.name': 'Ulhasnagar',
    'Indian.Jammu.name': 'Jammu',
    'Indian.Sangli-Miraj & Kupwad.name': 'Sangli-Miraj & Kupwad',
    'Indian.Mangalore.name': 'Mangalore',
    'Indian.Erode.name': 'Erode',
    'Indian.Belgaum.name': 'Belgaum',
    'Indian.Ambattur.name': 'Ambattur',
    'Indian.Tirunelveli.name': 'Tirunelveli',
    'Indian.Malegaon.name': 'Malegaon',
    'Indian.Gaya.name': 'Gaya',
    'Indian.Thiruvananthapuram.name': 'Thiruvananthapuram',
    'Indian.Udaipur.name': 'Udaipur',
    'Indian.Maheshtala.name': 'Maheshtala',
    'Indian.Davanagere.name': 'Davanagere',
    'Indian.Kozhikode.name': 'Kozhikode',
    'Indian.Kurnool.name': 'Kurnool',
    'Indian.Rajpur Sonarpur.name': 'Rajpur Sonarpur',
    'Indian.Rajahmundry.name': 'Rajahmundry',
    'Indian.Bokaro.name': 'Bokaro',
    'Indian.South Dumdum.name': 'South Dumdum',
    'Indian.Bellary.name': 'Bellary',
    'Indian.Patiala.name': 'Patiala',
    'Indian.Gopalpur.name': 'Gopalpur',
    'Indian.Agartala.name': 'Agartala',
    'Indian.Bhagalpur.name': 'Bhagalpur',
    'Indian.Muzaffarnagar.name': 'Muzaffarnagar',
    'Indian.Bhatpara.name': 'Bhatpara',
    'Indian.Panihati.name': 'Panihati',
    'Indian.Latur.name': 'Latur',
    'Indian.Dhule.name': 'Dhule',
    'Indian.Tirupati.name': 'Tirupati',
    'Indian.Rohtak.name': 'Rohtak',
    'Indian.Korba.name': 'Korba',
    'Indian.Bhilwara.name': 'Bhilwara',
    'Indian.Berhampur.name': 'Berhampur',
    'Indian.Muzaffarpur.name': 'Muzaffarpur',
    'Indian.Ahmednagar.name': 'Ahmednagar',
    'Indian.Mathura.name': 'Mathura',
    'Indian.Kollam.name': 'Kollam',
    'Indian.Avadi.name': 'Avadi',
    'Indian.Kadapa.name': 'Kadapa',
    'Indian.Kamarhati.name': 'Kamarhati',
    'Indian.Sambalpur.name': 'Sambalpur',
    'Indian.Bilaspur.name': 'Bilaspur',
    'Indian.Shahjahanpur.name': 'Shahjahanpur',
    'Indian.Satara.name': 'Satara',
    'Indian.Bijapur.name': 'Bijapur',
    'Indian.Kakinada.name': 'Kakinada',
    'Indian.Rampur.name': 'Rampur',
    'Indian.Shimoga.name': 'Shimoga',
    'Indian.Chandrapur.name': 'Chandrapur',
    'Indian.Junagadh.name': 'Junagadh',
    'Indian.Thrissur.name': 'Thrissur',
    'Indian.Alwar.name': 'Alwar',
    'Indian.Bardhaman.name': 'Bardhaman',
    'Indian.Kulti.name': 'Kulti',
    'Indian.Nizamabad.name': 'Nizamabad',
    'Indian.Parbhani.name': 'Parbhani',
    'Indian.Tumkur.name': 'Tumkur',
    'Indian.Khammam.name': 'Khammam',
    'Indian.Ozhukarai.name': 'Ozhukarai',
    'Indian.Bihar Sharif.name': 'Bihar Sharif',
    'Indian.Panipat.name': 'Panipat',
    'Indian.Darbhanga.name': 'Darbhanga',
    'Indian.Bally.name': 'Bally',
    'Indian.Aizawl.name': 'Aizawl',
    'Indian.Dewas.name': 'Dewas',
    'Indian.Ichalkaranji.name': 'Ichalkaranji',
    'Indian.Karnal.name': 'Karnal',
    'Indian.Bathinda.name': 'Bathinda',
    'Indian.Jalna.name': 'Jalna',
    'Indian.Eluru.name': 'Eluru',
    'Indian.Barasat.name': 'Barasat',
    'Indian.Kirari Suleman Nagar.name': 'Kirari Suleman Nagar',
    'Indian.Purnia.name': 'Purnia',
    'Indian.Satna.name': 'Satna',
    'Indian.Mau.name': 'Mau',
    'Indian.Sonipat.name': 'Sonipat',
    'Indian.Farrukhabad.name': 'Farrukhabad',
    'Indian.Sagar.name': 'Sagar',
    'Indian.Durg.name': 'Durg',
    'Indian.Imphal.name': 'Imphal',
    'Indian.Ratlam.name': 'Ratlam',
    'Indian.Hapur.name': 'Hapur',
    'Indian.Arrah.name': 'Arrah',
    'Indian.Anantapur.name': 'Anantapur',
    'Indian.Karimnagar.name': 'Karimnagar',
    'Indian.Etawah.name': 'Etawah',
    'Indian.Ambarnath.name': 'Ambarnath',
    'Indian.North Dumdum.name': 'North Dumdum',
    'Indian.Bharatpur.name': 'Bharatpur',
    'Indian.Begusarai.name': 'Begusarai',
    'Indian.New Delhi.name': 'New Delhi',
    'Indian.Gandhidham.name': 'Gandhidham',
    'Indian.Baranagar.name': 'Baranagar',
    'Indian.Tiruvottiyur.name': 'Tiruvottiyur',
    'Indian.Pondicherry.name': 'Pondicherry',
    'Indian.Sikar.name': 'Sikar',
    'Indian.Thoothukudi.name': 'Thoothukudi',
    'Indian.Rewa.name': 'Rewa',
    'Indian.Mirzapur.name': 'Mirzapur',
    'Indian.Raichur.name': 'Raichur',
    'Indian.Pali.name': 'Pali',
    'Indian.Ramagundam.name': 'Ramagundam',
    'Indian.Haridwar.name': 'Haridwar',
    'Indian.Vijayanagaram.name': 'Vijayanagaram',
    'Indian.Katihar.name': 'Katihar',
    'Indian.Nagercoil.name': 'Nagercoil',
    'Indian.Sri Ganganagar.name': 'Sri Ganganagar',
    'Indian.Karawal Nagar.name': 'Karawal Nagar',
    'Indian.Mango.name': 'Mango',
    'Indian.Thanjavur.name': 'Thanjavur',
    'Indian.Bulandshahr.name': 'Bulandshahr',
    'Indian.Uluberia.name': 'Uluberia',
    'Indian.Katni.name': 'Katni',
    'Indian.Sambhal.name': 'Sambhal',
    'Indian.Singrauli.name': 'Singrauli',
    'Indian.Nadiad.name': 'Nadiad',
    'Indian.Secunderabad.name': 'Secunderabad',
    'Indian.Naihati.name': 'Naihati',
    'Indian.Yamunanagar.name': 'Yamunanagar',
    'Indian.Bidhannagar.name': 'Bidhannagar',
    'Indian.Pallavaram.name': 'Pallavaram',
    'Indian.Bidar.name': 'Bidar',
    'Indian.Munger.name': 'Munger',
    'Indian.Panchkula.name': 'Panchkula',
    'Indian.Burhanpur.name': 'Burhanpur',
    'Indian.Raurkela Industrial Township.name': 'Raurkela Industrial Township',
    'Indian.Kharagpur.name': 'Kharagpur',
    'Indian.Dindigul.name': 'Dindigul',
    'Indian.Gandhinagar.name': 'Gandhinagar',
    'Indian.Hospet.name': 'Hospet',
    'Indian.Nangloi Jat.name': 'Nangloi Jat',
    'Indian.Malda.name': 'Malda',
    'Indian.Ongole.name': 'Ongole',
    'Indian.Deoghar.name': 'Deoghar',
    'Indian.Chapra.name': 'Chapra',
    'Indian.Haldia.name': 'Haldia',
    'Indian.Khandwa.name': 'Khandwa',
    'Indian.Nandyal.name': 'Nandyal',
    'Indian.Morena.name': 'Morena',
    'Indian.Amroha.name': 'Amroha',
    'Indian.Anand.name': 'Anand',
    'Indian.Bhind.name': 'Bhind',
    'Indian.Bhalswa Jahangir Pur.name': 'Bhalswa Jahangir Pur',
    'Indian.Madhyamgram.name': 'Madhyamgram',
    'Indian.Bhiwani.name': 'Bhiwani',
    'Indian.Berhampore.name': 'Berhampore',
    'Indian.Ambala.name': 'Ambala',
    'Indian.Morbi.name': 'Morbi',
    'Indian.Fatehpur.name': 'Fatehpur',
    'Indian.Raebareli.name': 'Raebareli',
    'Indian.Mahaboobnagar.name': 'Mahaboobnagar',
    'Indian.Chittoor.name': 'Chittoor',
    'Indian.Bhusawal.name': 'Bhusawal',
    'Indian.Orai.name': 'Orai',
    'Indian.Bahraich.name': 'Bahraich',
    'Indian.Vellore.name': 'Vellore',
    'Indian.Mehsana.name': 'Mehsana',
    'Indian.Raiganj.name': 'Raiganj',
    'Indian.Sirsa.name': 'Sirsa',
    'Indian.Danapur.name': 'Danapur',
    'Indian.Serampore.name': 'Serampore',
    'Indian.Sultan Pur Majra.name': 'Sultan Pur Majra',
    'Indian.Guna.name': 'Guna',
    'Indian.Jaunpur.name': 'Jaunpur',
    'Indian.Panvel.name': 'Panvel',
    'Indian.Shivpuri.name': 'Shivpuri',
    'Indian.Surendranagar Dudhrej.name': 'Surendranagar Dudhrej',
    'Indian.Unnao.name': 'Unnao',
    'Indian.Chinsurah.name': 'Chinsurah',
    'Indian.Alappuzha.name': 'Alappuzha',
    'Indian.Kottayam.name': 'Kottayam',
    'Indian.Machilipatnam.name': 'Machilipatnam',
    'Indian.Shimla.name': 'Shimla',
    'Indian.Adoni.name': 'Adoni',
    'Indian.Udupi.name': 'Udupi',
    'Indian.Tenali.name': 'Tenali',
    'Indian.Proddatur.name': 'Proddatur',
    'Indian.Saharsa.name': 'Saharsa',
    'Indian.Hindupur.name': 'Hindupur',
    'Indian.Sasaram.name': 'Sasaram',
    'Indian.Hajipur.name': 'Hajipur',
    'Indian.Bhimavaram.name': 'Bhimavaram',
    'Indian.Kumbakonam.name': 'Kumbakonam',
    'Indian.Dehri.name': 'Dehri',
    'Indian.Madanapalle.name': 'Madanapalle',
    'Indian.Siwan.name': 'Siwan',
    'Indian.Bettiah.name': 'Bettiah',
    'Indian.Guntakal.name': 'Guntakal',
    'Indian.Srikakulam.name': 'Srikakulam',
    'Indian.Motihari.name': 'Motihari',
    'Indian.Dharmavaram.name': 'Dharmavaram',
    'Indian.Gudivada.name': 'Gudivada',
    'Indian.Phagwara.name': 'Phagwara',
    'Indian.Narasaraopet.name': 'Narasaraopet',
    'Indian.Suryapet.name': 'Suryapet',
    'Indian.Miryalaguda.name': 'Miryalaguda',
    'Indian.Tadipatri.name': 'Tadipatri',
    'Indian.Karaikudi.name': 'Karaikudi',
    'Indian.Kishanganj.name': 'Kishanganj',
    'Indian.Jamalpur.name': 'Jamalpur',
    'Indian.Ballia.name': 'Ballia',
    'Indian.Kavali.name': 'Kavali',
    'Indian.Tadepalligudem.name': 'Tadepalligudem',
    'Indian.Amaravati.name': 'Amaravati',
    'Indian.Buxar.name': 'Buxar',
    'Indian.Jehanabad.name': 'Jehanabad',
    'Indian.Gangtok.name': 'Gangtok',

    // Mongol
    'Mongol.Ulaanbaatar.name': 'Ulaanbaatar',
    'Mongol.Erdenet.name': 'Erdenet',
    'Mongol.Darkhan.name': 'Darkhan',
    'Mongol.Choibalsan.name': 'Choibalsan',
    'Mongol.Mörön.name': 'Mörön',
    'Mongol.Nalaikh.name': 'Nalaikh',
    'Mongol.Bayankhongor.name': 'Bayankhongor',
    'Mongol.Ölgii.name': 'Ölgii',
    'Mongol.Khovd.name': 'Khovd',
    'Mongol.Arvaikheer.name': 'Arvaikheer',
    'Mongol.Ulaangom.name': 'Ulaangom',
    'Mongol.Baganuur.name': 'Baganuur',
    'Mongol.Sainshand.name': 'Sainshand',
    'Mongol.Tsetserleg.name': 'Tsetserleg',
    'Mongol.Sükhbaatar.name': 'Sükhbaatar',
    'Mongol.Öndörkhaan.name': 'Öndörkhaan',
    'Mongol.Dalanzadgad.name': 'Dalanzadgad',
    'Mongol.Züünkharaa.name': 'Züünkharaa',
    'Mongol.Uliastai.name': 'Uliastai',
    'Mongol.Altai.name': 'Altai',
    'Mongol.Baruun-Urt.name': 'Baruun-Urt',
    'Mongol.Mandalgovi.name': 'Mandalgovi',
    'Mongol.Zuunmod.name': 'Zuunmod',
    'Mongol.Zamyn-Üüd.name': 'Zamyn-Üüd',
    'Mongol.Bulgan.name': 'Bulgan',
    'Mongol.Kharkhorin.name': 'Kharkhorin',
    'Mongol.Choir.name': 'Choir',
    'Mongol.Bor-Öndör.name': 'Bor-Öndör',
    'Mongol.Sharyngo.name': 'Sharyngo',

    // Roman
    'Roman.Roma.name': 'Roma',
    'Roman.Ariminum.name': 'Ariminum',
    'Roman.Belum.name': 'Belum',
    'Roman.Placentia.name': 'Placentia',
    'Roman.Tarraco.name': 'Tarraco',
    'Roman.Italica.name': 'Italica',
    'Roman.Mod.name': 'Mod',
    'Roman.Salernum.name': 'Salernum',
    'Roman.Bononia.name': 'Bononia',
    'Roman.Forum Livii.name': 'Forum Livii',
    'Roman.Regium Lepidi.name': 'Regium Lepidi',
    'Roman.Aquileia.name': 'Aquileia',
    'Roman.Ascrivium.name': 'Ascrivium',
    'Roman.Valentia Edetanorum.name': 'Valentia Edetanorum',
    'Roman.Scalabis.name': 'Scalabis',
    'Roman.Villa Euracini.name': 'Villa Euracini',
    'Roman.Palma.name': 'Palma',
    'Roman.Colonia Narbo Martius.name': 'Colonia Narbo Martius',
    'Roman.Massa.name': 'Massa',
    'Roman.Pistoria.name': 'Pistoria',
    'Roman.Segovia.name': 'Segovia',
    'Roman.Gerunda.name': 'Gerunda',
    'Roman.Pompaelo.name': 'Pompaelo',
    'Roman.Naissus.name': 'Naissus',
    'Roman.Brigantium.name': 'Brigantium',
    'Roman.Florentia.name': 'Florentia',
    'Roman.Vesontio.name': 'Vesontio',
    'Roman.Lutetia Parisiorum.name': 'Lutetia Parisiorum',
    'Roman.Atuatuca Tungrorum.name': 'Atuatuca Tungrorum',
    'Roman.Iulia Romula Hispalis.name': 'Iulia Romula Hispalis',
    'Roman.Augusta Raurica.name': 'Augusta Raurica',
    'Roman.Noviodunum.name': 'Noviodunum',
    'Roman.Lugdunum.name': 'Lugdunum',
    'Roman.Siscia.name': 'Siscia',
    'Roman.Marsonia.name': 'Marsonia',
    'Roman.Augusta Treverorum.name': 'Augusta Treverorum',
    'Roman.Castra Legionis.name': 'Castra Legionis',
    'Roman.Augusta Taurinorum.name': 'Augusta Taurinorum',
    'Roman.Augusta Praetoria Salassorum.name': 'Augusta Praetoria Salassorum',
    'Roman.Emerita Augusta.name': 'Emerita Augusta',
    'Roman.Norba Caesarina.name': 'Norba Caesarina',
    'Roman.Lucus Augusta.name': 'Lucus Augusta',
    'Roman.Caesarea.name': 'Caesarea',
    'Roman.Bracara Augusta.name': 'Bracara Augusta',
    'Roman.Novaesium.name': 'Novaesium',
    'Roman.Castra Vetera.name': 'Castra Vetera',
    'Roman.Pons Drusi.name': 'Pons Drusi',
    'Roman.Ovilava.name': 'Ovilava',
    'Roman.Iuvavum.name': 'Iuvavum',
    'Roman.Augusta Vindelicorum.name': 'Augusta Vindelicorum',
    'Roman.Turicum.name': 'Turicum',
    'Roman.Asturica Augusta.name': 'Asturica Augusta',
    'Roman.Borbetomagus.name': 'Borbetomagus',
    'Roman.Caesaraugusta.name': 'Caesaraugusta',
    'Roman.Sirmium.name': 'Sirmium',
    'Roman.Sorviodurum.name': 'Sorviodurum',
    'Roman.Mogontiacum.name': 'Mogontiacum',
    'Roman.Argentoratum.name': 'Argentoratum',
    'Roman.Bonna.name': 'Bonna',
    'Roman.Noviomagus.name': 'Noviomagus',
    'Roman.Castellum apud Confluentes.name': 'Castellum apud Confluentes',
    'Roman.Divodurum.name': 'Divodurum',
    'Roman.Caesaromagus.name': 'Caesaromagus',
    'Roman.Ambianum.name': 'Ambianum',
    'Roman.Faventia Paterna Barcino.name': 'Faventia Paterna Barcino',
    'Roman.Abila.name': 'Abila',
    'Roman.Lousonna.name': 'Lousonna',
    'Roman.Ulpia Noviomagus Batavorum.name': 'Ulpia Noviomagus Batavorum',
    'Roman.Rigomagus.name': 'Rigomagus',
    'Roman.Aquae Mattiacorum.name': 'Aquae Mattiacorum',
    'Roman.Mursa.name': 'Mursa',
    'Roman.Emona.name': 'Emona',
    'Roman.Vindonissa.name': 'Vindonissa',
    'Roman.Aventicum.name': 'Aventicum',
    'Roman.Praetorium Agrippinae.name': 'Praetorium Agrippinae',
    'Roman.Lugdunum Batavorum.name': 'Lugdunum Batavorum',
    'Roman.Aequum.name': 'Aequum',
    'Roman.Londinium.name': 'Londinium',
    'Roman.Albanianis.name': 'Albanianis',
    'Roman.Lauri.name': 'Lauri',
    'Roman.Durovernum Cantiacorum.name': 'Durovernum Cantiacorum',
    'Roman.Regulbium.name': 'Regulbium',
    'Roman.Rutupiae.name': 'Rutupiae',
    'Roman.Viminacium.name': 'Viminacium',
    'Roman.Camulodunum.name': 'Camulodunum',
    'Roman.Traiectum.name': 'Traiectum',
    'Roman.Matilo.name': 'Matilo',
    'Roman.Forum Hadriani.name': 'Forum Hadriani',
    'Roman.Nigrum Pullum.name': 'Nigrum Pullum',
    'Roman.Lindum Colonia.name': 'Lindum Colonia',
    'Roman.Ratae Corieltauvorum.name': 'Ratae Corieltauvorum',
    'Roman.Durocobrivis.name': 'Durocobrivis',
    'Roman.Colonia Agrippina.name': 'Colonia Agrippina',
    'Roman.Durocornovium.name': 'Durocornovium',
    'Roman.Verulamium.name': 'Verulamium',
    'Roman.Letocetum.name': 'Letocetum',
    'Roman.Dubris.name': 'Dubris',
    'Roman.Danum.name': 'Danum',
    'Roman.Mediolanum.name': 'Mediolanum',
    'Roman.Isca Dumnoniorum.name': 'Isca Dumnoniorum',
    'Roman.Blestium.name': 'Blestium',
    'Roman.Viroconium Cornoviorum.name': 'Viroconium Cornoviorum',
    'Roman.Aquae Sulis.name': 'Aquae Sulis',
    'Roman.Durnovaria.name': 'Durnovaria',
    'Roman.Lindinis.name': 'Lindinis',
    'Roman.Ad Flexum.name': 'Ad Flexum',
    'Roman.Clausentum.name': 'Clausentum',
    'Roman.Venta Belgarum.name': 'Venta Belgarum',
    'Roman.Calleva Atrebatum.name': 'Calleva Atrebatum',
    'Roman.Duroliponte.name': 'Duroliponte',
    'Roman.Concangis.name': 'Concangis',
    'Roman.Condate.name': 'Condate',
    'Roman.Corinium Dobunnorum.name': 'Corinium Dobunnorum',
    'Roman.Eboracum.name': 'Eboracum',
    'Roman.Luguvalium.name': 'Luguvalium',
    'Roman.Arae Flaviae.name': 'Arae Flaviae',
    'Roman.Isca Augusta.name': 'Isca Augusta',
    'Roman.Lagentium.name': 'Lagentium',
    'Roman.Lopodunum.name': 'Lopodunum',
    'Roman.Moridunum.name': 'Moridunum',
    'Roman.Venta Silurum.name': 'Venta Silurum',
    'Roman.Deva Victrix.name': 'Deva Victrix',
    'Roman.Mamucium.name': 'Mamucium',
    'Roman.Olicana.name': 'Olicana',
    'Roman.Vinovia.name': 'Vinovia',
    'Roman.Bonames.name': 'Bonames',
    'Roman.Coria.name': 'Coria',
    'Roman.Gerulata.name': 'Gerulata',
    'Roman.Vindobona.name': 'Vindobona',
    'Roman.Biriciana.name': 'Biriciana',
    'Roman.Cannstatt Castrum.name': 'Cannstatt Castrum',
    'Roman.Colonia Nervia Glevensium.name': 'Colonia Nervia Glevensium',
    'Roman.Sumelocenna.name': 'Sumelocenna',
    'Roman.Traiectum ad Nicrem.name': 'Traiectum ad Nicrem',
    'Roman.Castra Batavar.name': 'Castra Batavar',
    'Roman.Nida.name': 'Nida',
    'Roman.Lactodurum.name': 'Lactodurum',
    'Roman.Bovium.name': 'Bovium',
    'Roman.Burgodunum.name': 'Burgodunum',
    'Roman.Lentia.name': 'Lentia',
    'Roman.Aquae Granni.name': 'Aquae Granni',
    'Roman.Complutum.name': 'Complutum',
    'Roman.Aeminium.name': 'Aeminium',
    'Roman.Traiectum ad Mosam.name': 'Traiectum ad Mosam',
    'Roman.Coriovallum.name': 'Coriovallum',
    'Roman.Portus Lemanis.name': 'Portus Lemanis',
    'Roman.Andautonia.name': 'Andautonia',
    'Roman.Salona.name': 'Salona',
    'Roman.Cibalae.name': 'Cibalae',
    'Roman.Aquae Helveticae.name': 'Aquae Helveticae',
    'Roman.Aquincum.name': 'Aquincum',
    'Roman.Apulum.name': 'Apulum',
    'Roman.Pons Aelius.name': 'Pons Aelius',
    'Roman.Durovigutum.name': 'Durovigutum',
    'Roman.Aelia Capitolina.name': 'Aelia Capitolina',
    'Roman.Ala.name': 'Ala',
    'Roman.Castra Regina.name': 'Castra Regina',
    'Roman.Theranda.name': 'Theranda',
    'Roman.Pomaria.name': 'Pomaria',
    'Roman.Partiscum.name': 'Partiscum',
    'Roman.Gesoriacum.name': 'Gesoriacum',
    'Roman.Aurelia Aquensis.name': 'Aurelia Aquensis',
    'Roman.Urbs Aurelianorum.name': 'Urbs Aurelianorum',
    'Roman.Venetiae.name': 'Venetiae',
    'Roman.Ragusium.name': 'Ragusium',

    // Russian
    'Russian.Moscow.name': 'Moscow',
    'Russian.Saint Petersburg.name': 'Saint Petersburg',
    'Russian.Novosibirsk.name': 'Novosibirsk',
    'Russian.Yekaterinburg.name': 'Yekaterinburg',
    'Russian.Nizhny Novgorod.name': 'Nizhny Novgorod',
    'Russian.Kazan.name': 'Kazan',
    'Russian.Chelyabinsk.name': 'Chelyabinsk',
    'Russian.Omsk.name': 'Omsk',
    'Russian.Samara.name': 'Samara',
    'Russian.Ufa.name': 'Ufa',
    'Russian.Rostov-on-Don.name': 'Rostov-on-Don',
    'Russian.Krasnoyarsk.name': 'Krasnoyarsk',
    'Russian.Voronezh.name': 'Voronezh',
    'Russian.Perm.name': 'Perm',
    'Russian.Volgograd.name': 'Volgograd',
    'Russian.Krasnodar.name': 'Krasnodar',
    'Russian.Saratov.name': 'Saratov',
    'Russian.Tyumen.name': 'Tyumen',
    'Russian.Makhachkala.name': 'Makhachkala',
    'Russian.Tolyatti.name': 'Tolyatti',
    'Russian.Barnaul.name': 'Barnaul',
    'Russian.Ulyanovsk.name': 'Ulyanovsk',
    'Russian.Izhevsk.name': 'Izhevsk',
    'Russian.Vladivostok.name': 'Vladivostok',
    'Russian.Irkutsk.name': 'Irkutsk',
    'Russian.Khabarovsk.name': 'Khabarovsk',
    'Russian.Yaroslavl.name': 'Yaroslavl',
    'Russian.Tomsk.name': 'Tomsk',
    'Russian.Orenburg.name': 'Orenburg',
    'Russian.Kemerovo.name': 'Kemerovo',
    'Russian.Novokuznetsk.name': 'Novokuznetsk',
    'Russian.Tula.name': 'Tula',
    'Russian.Ryazan.name': 'Ryazan',
    'Russian.Kirov.name': 'Kirov',
    'Russian.Astrakhan.name': 'Astrakhan',
    'Russian.Naberezhnye Chelny.name': 'Naberezhnye Chelny',
    'Russian.Sochi.name': 'Sochi',
    'Russian.Penza.name': 'Penza',
    'Russian.Lipetsk.name': 'Lipetsk',
    'Russian.Cheboksary.name': 'Cheboksary',
    'Russian.Balashikha.name': 'Balashikha',
    'Russian.Kaliningrad.name': 'Kaliningrad',
    'Russian.Kursk.name': 'Kursk',
    'Russian.Stavropol.name': 'Stavropol',
    'Russian.Ulan-Ude.name': 'Ulan-Ude',
    'Russian.Bryansk.name': 'Bryansk',
    'Russian.Tver.name': 'Tver',
    'Russian.Magnitogorsk.name': 'Magnitogorsk',
    'Russian.Ivanovo.name': 'Ivanovo',
    'Russian.Belgorod.name': 'Belgorod',
    'Russian.Surgut.name': 'Surgut',
    'Russian.Vladimir.name': 'Vladimir',
    'Russian.Arkhangelsk.name': 'Arkhangelsk',
    'Russian.Nizhny Tagil.name': 'Nizhny Tagil',
    'Russian.Kaluga.name': 'Kaluga',
    'Russian.Chita.name': 'Chita',
    'Russian.Saransk.name': 'Saransk',
    'Russian.Yakutsk.name': 'Yakutsk',
    'Russian.Smolensk.name': 'Smolensk',
    'Russian.Podolsk.name': 'Podolsk',
    'Russian.Volzhsky.name': 'Volzhsky',
    'Russian.Vladikavkaz.name': 'Vladikavkaz',
    'Russian.Vologda.name': 'Vologda',
    'Russian.Cherepovets.name': 'Cherepovets',
    'Russian.Kurgan.name': 'Kurgan',
    'Russian.Oryol.name': 'Oryol',
    'Russian.Grozny.name': 'Grozny',
    'Russian.Murmansk.name': 'Murmansk',
    'Russian.Tambov.name': 'Tambov',
    'Russian.Petrozavodsk.name': 'Petrozavodsk',
    'Russian.Sterlitamak.name': 'Sterlitamak',
    'Russian.Kostroma.name': 'Kostroma',
    'Russian.Nizhnevartovsk.name': 'Nizhnevartovsk',
    'Russian.Novorossiysk.name': 'Novorossiysk',
    'Russian.Yoshkar-Ola.name': 'Yoshkar-Ola',
    'Russian.Taganrog.name': 'Taganrog',
    'Russian.Komsomolsk-on-Amur.name': 'Komsomolsk-on-Amur',
    'Russian.Khimki.name': 'Khimki',
    'Russian.Syktyvkar.name': 'Syktyvkar',
    'Russian.Nalchik.name': 'Nalchik',
    'Russian.Nizhnekamsk.name': 'Nizhnekamsk',
    'Russian.Shakhty.name': 'Shakhty',
    'Russian.Dzerzhinsk.name': 'Dzerzhinsk',
    'Russian.Bratsk.name': 'Bratsk',
    'Russian.Orsk.name': 'Orsk',
    'Russian.Angarsk.name': 'Angarsk',
    'Russian.Engels.name': 'Engels',
    'Russian.Blagoveshchensk.name': 'Blagoveshchensk',
    'Russian.Stary Oskol.name': 'Stary Oskol',
    'Russian.Veliky Novgorod.name': 'Veliky Novgorod',
    'Russian.Korolyov.name': 'Korolyov',
    'Russian.Pskov.name': 'Pskov',
    'Russian.Mytishchi.name': 'Mytishchi',
    'Russian.Biysk.name': 'Biysk',
    'Russian.Lyubertsy.name': 'Lyubertsy',
    'Russian.Prokopyevsk.name': 'Prokopyevsk',
    'Russian.Yuzhno-Sakhalinsk.name': 'Yuzhno-Sakhalinsk',
    'Russian.Balakovo.name': 'Balakovo',
    'Russian.Armavir.name': 'Armavir',
    'Russian.Rybinsk.name': 'Rybinsk',
    'Russian.Severodvinsk.name': 'Severodvinsk',
    'Russian.Abakan.name': 'Abakan',
    'Russian.Petropavlovsk-Kamchatsky.name': 'Petropavlovsk-Kamchatsky',
    'Russian.Norilsk.name': 'Norilsk',
    'Russian.Syzran.name': 'Syzran',
    'Russian.Volgodonsk.name': 'Volgodonsk',
    'Russian.Ussuriysk.name': 'Ussuriysk',
    'Russian.Kamensk-Uralsky.name': 'Kamensk-Uralsky',
    'Russian.Novocherkassk.name': 'Novocherkassk',
    'Russian.Zlatoust.name': 'Zlatoust',
    'Russian.Elektrostal.name': 'Elektrostal',
    'Russian.Almetyevsk.name': 'Almetyevsk',
    'Russian.Krasnogorsk.name': 'Krasnogorsk',
    'Russian.Salavat.name': 'Salavat',
    'Russian.Miass.name': 'Miass',
    'Russian.Nakhodka.name': 'Nakhodka',
    'Russian.Kopeysk.name': 'Kopeysk',
    'Russian.Pyatigorsk.name': 'Pyatigorsk',
    'Russian.Rubtsovsk.name': 'Rubtsovsk',
    'Russian.Berezniki.name': 'Berezniki',
    'Russian.Kolomna.name': 'Kolomna',
    'Russian.Maykop.name': 'Maykop',
    'Russian.Odintsovo.name': 'Odintsovo',
    'Russian.Khasavyurt.name': 'Khasavyurt',
    'Russian.Kovrov.name': 'Kovrov',
    'Russian.Kislovodsk.name': 'Kislovodsk',
    'Russian.Neftekamsk.name': 'Neftekamsk',
    'Russian.Nefteyugansk.name': 'Nefteyugansk',
    'Russian.Novocheboksarsk.name': 'Novocheboksarsk',
    'Russian.Serpukhov.name': 'Serpukhov',
    'Russian.Shchyolkovo.name': 'Shchyolkovo',
    'Russian.Novomoskovsk.name': 'Novomoskovsk',
    'Russian.Bataysk.name': 'Bataysk',
    'Russian.Pervouralsk.name': 'Pervouralsk',
    'Russian.Domodedovo.name': 'Domodedovo',
    'Russian.Derbent.name': 'Derbent',
    'Russian.Cherkessk.name': 'Cherkessk',
    'Russian.Orekhovo-Zuyevo.name': 'Orekhovo-Zuyevo',
    'Russian.Nevinnomyssk.name': 'Nevinnomyssk',
    'Russian.Dimitrovgrad.name': 'Dimitrovgrad',
    'Russian.Nazran.name': 'Nazran',
    'Russian.Kyzyl.name': 'Kyzyl',
    'Russian.Oktyabrsky.name': 'Oktyabrsky',
    'Russian.Obninsk.name': 'Obninsk',
    'Russian.Kaspiysk.name': 'Kaspiysk',
    'Russian.Novy Urengoy.name': 'Novy Urengoy',
    'Russian.Ramenskoye.name': 'Ramenskoye',
    'Russian.Kamyshin.name': 'Kamyshin',
    'Russian.Murom.name': 'Murom',
    'Russian.Zhukovsky.name': 'Zhukovsky',

    // Zulu
    'Zulu.uMgungundlovu.name': 'uMgungundlovu',
    'Zulu.Nobamba.name': 'Nobamba',
    'Zulu.Bulawayo.name': 'Bulawayo',
    'Zulu.KwaDukuza.name': 'KwaDukuza',
    'Zulu.Nongoma.name': 'Nongoma',
    'Zulu.oNdini.name': 'oNdini',
    'Zulu.Nodwengu.name': 'Nodwengu',
    'Zulu.Ndondakusuka.name': 'Ndondakusuka',
    'Zulu.Babanango.name': 'Babanango',
    'Zulu.Khangela.name': 'Khangela',
    'Zulu.Kwahlomendlini.name': 'Kwahlomendlini',
    'Zulu.Hlobane.name': 'Hlobane',
    'Zulu.eThekwini.name': 'eThekwini',
    'Zulu.Mlambongwenya.name': 'Mlambongwenya',
    'Zulu.Eziqwaqweni.name': 'Eziqwaqweni',
    'Zulu.Isiphezi.name': 'Isiphezi',
    'Zulu.Masotsheni.name': 'Masotsheni',
    'Zulu.Mtunzini.name': 'Mtunzini',
    'Zulu.Nyakamubi.name': 'Nyakamubi',
    'Zulu.Hlatikulu.name': 'Hlatikulu',
    'Zulu.Mthonjaneni.name': 'Mthonjaneni',
    'Zulu.Empangeni.name': 'Empangeni',
    'Zulu.Pongola.name': 'Pongola',
    'Zulu.Tungela.name': 'Tungela',
    'Zulu.Kwamashu.name': 'Kwamashu',
    'Zulu.Ingwavuma.name': 'Ingwavuma',
    'Zulu.Hluhluwe.name': 'Hluhluwe',
    'Zulu.Mtubatuba.name': 'Mtubatuba',
    'Zulu.Mhlahlandlela.name': 'Mhlahlandlela',
    'Zulu.Mthatha.name': 'Mthatha',
    'Zulu.Maseru.name': 'Maseru',
    'Zulu.Lobamba.name': 'Lobamba',
    'Zulu.Qunu.name': 'Qunu',
  }
);

i18next.addResources(
  'en',
  'yield',
  {
    'Gold.name': 'Gold',
    'Luxuries.name': 'Luxuries',
    'Research.name': 'Research',
  }
);
