import i18next from "i18next";

i18next.addResources(
  'en',
  'civilization',
  {
    'American.name': 'American',
    'American.nation': 'America',
    'American.plural': 'Americans',
    'Aztec.name': 'Aztec',
    'Aztec.nation': 'the Aztec Empire',
    'Aztec.plural': 'Aztecs',
    'Babylonian.name': 'Babylonian',
    'Babylonian.nation': 'Babylon',
    'Babylonian.plural': 'Babylonians',
    'Chinese.name': 'Chinese',
    'Chinese.nation': 'China',
    'Chinese.plural': 'Chinese',
    'Egyptian.name': 'Egyptian',
    'Egyptian.nation': 'Egypt',
    'Egyptian.plural': 'Egyptians',
    'English.name': 'English',
    'English.nation': 'England',
    'English.plural': 'English',
    'French.name': 'French',
    'French.nation': 'France',
    'French.plural': 'French',
    'German.name': 'German',
    'German.nation': 'Germany',
    'German.plural': 'Germans',
    'Greek.name': 'Greek',
    'Greek.nation': 'Greece',
    'Greek.plural': 'Greeks',
    'Indian.name': 'Indian',
    'Indian.nation': 'India',
    'Indian.plural': 'Indians',
    'Mongol.name': 'Mongol',
    'Mongol.nation': 'Mongolia',
    'Mongol.plural': 'Mongols',
    'Roman.name': 'Roman',
    'Roman.nation': 'Rome',
    'Roman.plural': 'Romans',
    'Russian.name': 'Russian',
    'Russian.nation': 'Russia',
    'Russian.plural': 'Russians',
    'Zulu.name': 'Zulu',
    'Zulu.nation': 'Zululand',
    'Zulu.plural': 'Zulus',

    // Leaders
    'Leader.AbrahamLincoln.name': 'Abraham Lincoln',
    'Leader.MoctezumaII.name': 'Moctezuma II',
    'Leader.Hammurabi.name': 'Hammurabi',
    'Leader.MaoZedong.name': 'Mao Zedong',
    'Leader.RamessesII.name': 'Ramesses II',
    'Leader.ElizabethI.name': 'Elizabeth I',
    'Leader.NapoleonI.name': 'Napoleon I',
    'Leader.FrederickTheGreat.name': 'Frederick the Great',
    'Leader.AlexanderTheGreat.name': 'Alexander the Great',
    'Leader.MahatmaGandhi.name': 'Mahatma Gandhi',
    'Leader.GenghisKhan.name': 'Genghis Khan',
    'Leader.JuliusCaesar.name': 'Julius Caesar',
    'Leader.JosephStalin.name': 'Joseph Stalin',
    'Leader.Shaka.name': 'Shaka',
  }
);
