import i18next from "i18next";

i18next.addResources(
  'en',
  'trade',
  {
    'Luxuries.name': 'Luxuries',
    'Research.name': 'Research',
    'Tax.name': 'Tax',
  }
);
